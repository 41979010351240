import { SearchParams as SearchParamsType } from '@/types/SaveSearch';
import isEmpty from 'lodash/isEmpty';

function getCategoryName(facet) {
    const { categoryId } = facet;

    if (categoryId === facet.l1CategoryId) {
        return facet.l1CategoryName;
    } else if (categoryId === facet.l2CategoryId) {
        return facet.l2CategoryName;
    } else if (categoryId === facet.l3CategoryId) {
        return facet.l3CategoryName;
    } else if (categoryId === facet.l4CategoryId) {
        return facet.l4CategoryName;
    }
}

function processFacets(facets) {
    /*
     * Order of Precedence for Search Alert
     * [creator + category]
     * [material + category]
     * [style + category]
     * [origin + category]
     * [origin + material]
     * [category]
     */
    let searchQuery: SearchParamsType = { keyword: '' };
    let searchType = '';

    if (isEmpty(facets)) {
        return { searchQuery, searchType };
    }
    const { categories, creators, materialsTechniques, origins, stylePeriods } = facets;

    if (creators.length && categories.length) {
        const ids = `${creators[0].l1CategoryId},${categories[0].categoryId}`;

        searchQuery.category_id = ids;
        searchType = `${creators[0].l1CategoryName} ${getCategoryName(categories[0])}`;
    } else if (materialsTechniques.length && categories.length) {
        const ids = `${materialsTechniques[0].l1CategoryId},${categories[0].categoryId}`;

        searchQuery.category_id = ids;
        searchType = `${materialsTechniques[0].l1CategoryName} ${getCategoryName(categories[0])}`;
    } else if (stylePeriods.length && categories.length) {
        const ids = `${stylePeriods[0].l1CategoryId},${categories[0].categoryId}`;

        searchQuery.category_id = ids;
        searchType = `${stylePeriods[0].l1CategoryName} ${getCategoryName(categories[0])}`;
    } else if (origins.length && categories.length) {
        const ids = `${origins[0].categoryId},${categories[0].categoryId}`;

        searchQuery.category_id = ids;
        searchType = `${getCategoryName(origins[0])} ${getCategoryName(categories[0])}`;
    } else if (origins.length && materialsTechniques.length) {
        const ids = `${origins[0].categoryId},${materialsTechniques[0].l1CategoryId}`;

        searchQuery.category_id = ids;
        searchType = `${getCategoryName(origins[0])} ${materialsTechniques[0].l1CategoryName}`;
    } else if (categories.length) {
        const ids = `${categories[0].categoryId}`;

        searchQuery.category_id = ids;
        searchType = `${getCategoryName(categories[0])}`;
    }

    return { searchQuery, searchType };
}

export const getItemSavedSearchQuery = (facets) => {
    const { searchQuery } = processFacets(facets);

    return searchQuery;
};

export const getItemSavedSearchType = (facets) => {
    const { searchType } = processFacets(facets);

    return searchType;
};

export const getFacetIds = (facets) => {
    var ids = [];
    if (isEmpty(facets)) {
        return ids;
    }

    const { categories, creators, materialsTechniques, origins, stylePeriods } = facets;
    if (categories.length) {
        ids.push(categories[0].categoryId);
    }
    if (creators.length) {
        ids.push(creators[0].l1CategoryId);
    }
    if (materialsTechniques.length) {
        ids.push(materialsTechniques[0].l1CategoryId);
    }
    if (origins.length) {
        ids.push(origins[0].categoryId);
    }
    if (stylePeriods.length) {
        ids.push(stylePeriods[0].l1CategoryId);
    }
    return ids;
};
