import { translateAccents } from './translateCharacterAccents';

/**
 * toTitleCase - converts incoming slug-string to Title Case String Format
 * for use with PriceResultsPage SEO
 * parts borrowed from https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
 * @param str string
 * @returns string formatted in Title Case
 */
export const slugToTitleCase = (str: string) => {
    return str
        .toLowerCase()
        .replace(/-/g, ' ')
        .replace(/(^|\s)\S/g, (wordFirstChar) => {
            return wordFirstChar.toUpperCase();
        });
};

/**
 * addOrsBetweenKeywords - support for various search enhancement testting
 * adds OR between keywords if number of words in string is greater than maxWords
 * @param str string to parse
 * @param maxWords number of words to allow before inserting OR strings
 * @returns string
 */
export const addOrsBetweenKeywords = (str: string, maxWords = 3) => {
    const keywords = str.split(/\s+/);
    let updatedKeyword = keywords.join(' ');
    if (keywords.length > maxWords) {
        updatedKeyword = keywords.map((word) => `${word}`).join(' OR ');
    }
    return updatedKeyword;
};

/**
 * sanitizeSearchKey - strips accents from characters (replacing with standard latin chars)
 * and lowercase.
 * @param key string to sanitize
 * @returns string
 */
export const sanitizeSearchKey = (key: string = '') => {
    return translateAccents(key).toLowerCase();
};
