const getSubFromToken = (token: string = ''): number => {
    if (!token || typeof token !== 'string') {
        return 0;
    }
    const splitToken = token.split('.');
    if (splitToken.length < 2) {
        return 0;
    }
    try {
        const decodedToken = atob(splitToken[1]);
        if (!decodedToken) {
            return 0;
        }
        const payload = JSON.parse(decodedToken);
        return Number(payload?.sub || 0);
    } catch (e) {
        return 0;
    }
};

export default getSubFromToken;
