export const DONE = 'done';

// Part of 'Onboarding Walkthrough'
export enum FirstTimeWalkthroughSteps {
    Done = 'done',
    FutureItem = 'ft2',
    Landing = 'ft1',
    LiveItem = 'ft3',
    Search = 'ft4',
}

// Part of 'Onboarding Walkthrough'
export enum AccountHolderWalkthroughSteps {
    Done = 'done',
    Returning = 'ah1',
}

// Part of 'Onboarding Walkthrough'
export enum NewAccountWalkthroughSteps {
    Done = 'done',
    FirstTimeAnyPage = 'na1',
    Item1 = 'na2_1',
    Item2 = 'na2_2',
    Item3 = 'na2_3',
    Item4 = 'na2_4',
    Item5 = 'na2_5',
}

export type OnboardingWalkthroughSteps =
    | FirstTimeWalkthroughSteps
    | AccountHolderWalkthroughSteps
    | NewAccountWalkthroughSteps
    | typeof DONE
    | `${FirstTimeWalkthroughSteps.Landing}Title`;
