import { setGlobalPerfLoggerRequestHooks } from '@/redux/api/helpers';
import noop from 'lodash/noop';
import qs from 'qs';

export type RequestPerformanceStats = {
    endTime?: number;
    error?: any;
    method?: string;
    startTime: number;
    statusCode?: number;
    totalTime?: number;
    url: string;
    waterfall?: number;
};

type PerformanceObj = {
    now: () => number;
};

// elapsed time (in milliseconds) to consider next api call in another waterfall
const WATERFALL_THRESHOLD = 70;

/**
 * Builds a full url with query params from a superagent request object
 *
 * the request object provides a `qs` object of params on the server and a `_query` string array on the client
 */
const buildFullApiUrl = (request: { _query?: string[]; qs?: object; url: string }): string => {
    let webQueryParams = {};
    request._query.forEach((q) => {
        webQueryParams = { ...webQueryParams, ...qs.parse(q) };
    });

    const baseRequestUrl = request.url.split('?')[0];
    const baseRequestUrlParams = request.url.split('?')[1] || '';
    const queryString = qs.stringify({
        ...qs.parse(baseRequestUrlParams),
        ...request.qs,
        ...webQueryParams,
    });

    let requestUrl = baseRequestUrl;

    if (queryString) {
        requestUrl = `${baseRequestUrl}?${queryString}`;
    }

    return requestUrl;
};

export const logger = (performance: PerformanceObj): RequestPerformanceStats[] => {
    let requests: RequestPerformanceStats[] = [];

    setGlobalPerfLoggerRequestHooks({
        onBuildRequest: noop,
        // handle request end
        onHandleResponse: (request, response, error = undefined) => {
            const requestUrl = buildFullApiUrl(request);

            const index = requests.findIndex((req) => req.url === requestUrl && !req.endTime);
            const endTime = performance.now();
            const totalTime = endTime - requests[index]?.startTime || 0;

            requests[index] = {
                ...requests[index],
                endTime,
                error,
                method: request.method,
                statusCode: response?.statusCode,
                totalTime,
            };
        },
        // handle request start
        onSendRequest: (request: { qs: object; url: string }) => {
            requests.push({
                startTime: performance.now(),
                url: buildFullApiUrl(request),
            });
        },
    });

    return requests;
};

// Calculate the number of request waterfalls among all api calls made to load page data
const calculateNumRequestWaterfalls = (requests: RequestPerformanceStats[]): number => {
    let numWaterfalls = 0;

    requests.forEach((req, i) => {
        if (requests.length === i + 1) {
            req.waterfall = numWaterfalls;
            return;
        }

        if (i === 0) {
            numWaterfalls++;
            req.waterfall = numWaterfalls;
            return;
        }

        const currentStartTime = req.startTime;
        const nextStartTime = requests[i + 1].startTime;
        req.waterfall = numWaterfalls;

        // assume a network waterfall would mean >= WATERFALL_THRESHOLD (in milliseconds) between requests
        if (currentStartTime + WATERFALL_THRESHOLD <= nextStartTime) {
            numWaterfalls++;
        }
    });

    return numWaterfalls;
};

export type ApiFetchPerformanceStats = {
    numRequests: number;
    numWaterfalls: number;
    requests: RequestPerformanceStats[];
    totalFetchTime: number;
};

export const getStats = (requests: RequestPerformanceStats[]): ApiFetchPerformanceStats => {
    let firstRequestStart = Math.min(...requests.map((req) => req.startTime));
    let lastRequestEnd = Math.max(...requests.map((req) => req.endTime));

    const numWaterfalls = calculateNumRequestWaterfalls(requests);
    const totalFetchTime = requests.length ? Number((lastRequestEnd - firstRequestStart).toFixed(2)) : 0;
    const numRequests = requests.length;

    return {
        numRequests,
        numWaterfalls,
        requests,
        totalFetchTime,
    };
};
