import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';

type TrackSearchFollowedPayload = TrackingPayload & {
    searchKeywords: string;
};

export const trackSearchFollowedAnalytics = (payload: TrackSearchFollowedPayload) => {
    track(TrackingEvents.Search.SearchFollowed, payload);
};
