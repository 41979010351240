import { BidderAddress, emptyBidderAddress } from './BidderAddresses';
import { ShippingMethods } from '@/enums/shippingMethod';

export type OtherCharge = {
    amount: number; // 1.00
    chargeID?: string;
    explanation?: string;
    name: string;
};

export type Payment = {
    amount: number; // 6.90
    date?: string; // "2019-10-09 16:54:17"
    lap?: string; // "LAP" or ""
    p_type?: string; // "stripe"
    paymentID?: string;
    status?: string; //"success"
    ts_now_ts: number; // 1570658057
    ts_recieved_ts?: number; // 1570658057
};

export type InvoiceItem = {
    catalogId: string;
    imageVersion: string;
    itemId: string;
    salePrice: string; //in dollars, "60.12"
    sellerId: string;
    sellerName: string;
    title: string;
};

export type Invoice = {
    atgpay: boolean;
    balanceDue: number;
    balanceDueSubtotal: number;
    buyersPremium: number;
    currency: string;
    highRisk: boolean;
    houseShippingQuote: number;
    id: number;
    invoiceTotal: number;
    itemTotal: number;
    laPayment: number;
    otherCharges: OtherCharge[];
    payments: Payment[];
    paymentsTotal: number;
    processingFee: number;
    reopened?: boolean;
    salesTax: number;
    sent: boolean;
    shipping: number;
    shippingMethod?: ShippingMethods;
};

export type CheckoutInvoice = {
    invoice: Invoice;
    invoiceAddress: BidderAddress;
    items: InvoiceItem[];
    sent: boolean;
    shippingMethod: ShippingMethods;
};

export const emptyCheckoutInvoice: CheckoutInvoice = {
    invoice: {
        atgpay: false,
        balanceDue: 0,
        balanceDueSubtotal: 0,
        buyersPremium: 0,
        currency: '',
        highRisk: false,
        houseShippingQuote: 0,
        id: 0,
        invoiceTotal: 0,
        itemTotal: 0,
        laPayment: 0,
        otherCharges: [],
        payments: [],
        paymentsTotal: 0,
        processingFee: 0,
        reopened: false,
        salesTax: 0,
        sent: false,
        shipping: 0,
    },
    invoiceAddress: emptyBidderAddress,
    items: [],
    sent: false,
    shippingMethod: ShippingMethods.BidderShipping,
};

export const getEmptyCheckoutInvoice = (hasLiveShippingAvailable: boolean = false): CheckoutInvoice => ({
    ...emptyCheckoutInvoice,
    shippingMethod: hasLiveShippingAvailable ? ShippingMethods.LiveShipping : ShippingMethods.BidderShipping,
});
