/* eslint-disable sort-keys/sort-keys-fix */

// Just a note to how all of these work, basically most of the sorts map over their sort options
// Then use formatMessage from react-intl to take the key and set it to one of these
// Then you get what you ask for. The "fixes" are often one offs and can be found relatively easily
// As long as you look for specific SearchSortEnum assignments not in tests or in arrays.

// Here's a non exhaustive list of where they are fixed and used.
// --- "Fixes" ---
// NOTE - This is the same list as below with some additions.
// OLD vs NEW - src/intl/en.ts
// OLD vs NEW - src/components/Pagination/helpers/filterSortOptions.ts
// OLD vs NEW - src/pages/SavedItems/SavedItemsPage.tsx
// OLD vs NEW - src/pages/SavedItems/components/SavedItemsSlideMenu.tsx
// Recent vs Soonest - src/components/SearchResults/SearchSort.tsx
// Newest vs Soonest - src/components/SearchResults/SearchSort.tsx
// --- "Uses" ----
// NOTE - This is uses other than the obvious ones from fixes.
// src/pages/AuctionCalendar/AuctionCalendarPageHeader.tsx
// src/pages/Catalog/CatalogPageItems.tsx

// prettier-ignore
/* eslint-disable typescript-sort-keys/string-enum */
export enum SearchSortAndDirection {
    SavedMostRecent      = '-saved',
    SavedOldest          = 'saved',
    ViewedMostRecent     = '-viewed',
    ViewedOldest         = 'viewed',
    BestMatch            = '-relevance',
    SavedRecentFirst     = 'dateSaved',
    EstimateHighest      = '-estimatedPrice',
    EstimateLowest       = 'estimatedPrice',       // Used on catalog pages
    Popularity           = '-popularity',          // Here for compatability
    LotNumberLowest      = 'lotNumber',
    MostBids             = '-bidCount',
    NewlyListed          = '-publishDate',
    PriceHighest         = '-currentBid',
    PriceLowest          = 'currentBid',
    Promoted             = 'promoted',
    MostRecentViews      = 'recentViews',
    HammerPriceHighest   = '-hammerPrice',
    // This is quite the strange phenomenon.
    // Depending on the page this key actually means oldest.
    // Tracking current "fixes" above: (you can also global search for `.TimeOldest`)
    TimeNewest           = '-saleStart',
    TimeOldest           = '-saleStart(legacy)',
    TimeMostRecent       = 'saleStart',
    TimeStartingFurthest = '-saleStartUpcoming',
    TimeStartingSoonest  = 'saleStartUpcoming',
}
/* eslint-enable typescript-sort-keys/string-enum */
