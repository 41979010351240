import { ItemFacet } from '@/types/ItemFacets';

export type CategoryLevel = {
    categoryId: number;
    categoryLabel: string;
    categoryName: string;
    categoryUrl: string;
};

export const getCategoryLevel = (item: ItemFacet | undefined): CategoryLevel => {
    if (typeof item === 'undefined') {
        return {
            categoryId: 0,
            categoryLabel: '',
            categoryName: '',
            categoryUrl: '',
        };
    }
    let categoryId;
    if (item.categoryId) {
        categoryId = item.categoryId;
    } else {
        categoryId = item.l1CategoryId;
    }

    if (categoryId === item.l1CategoryId) {
        return {
            categoryId,
            categoryLabel: item.l1CategoryLabel,
            categoryName: item.l1CategoryName,
            categoryUrl: item.l1CategoryUrl,
        };
    } else if (categoryId === item.l2CategoryId) {
        return {
            categoryId,
            categoryLabel: item.l2CategoryLabel || '',
            categoryName: item.l2CategoryName || '',
            categoryUrl: item.l2CategoryUrl || '',
        };
    } else if (categoryId === item.l3CategoryId) {
        return {
            categoryId,
            categoryLabel: item.l3CategoryLabel || '',
            categoryName: item.l3CategoryName || '',
            categoryUrl: item.l3CategoryUrl || '',
        };
    } else if (categoryId === item.l4CategoryId) {
        return {
            categoryId,
            categoryLabel: item.l4CategoryLabel || '',
            categoryName: item.l4CategoryName || '',
            categoryUrl: item.l4CategoryUrl || '',
        };
    }

    return {
        categoryId: 0,
        categoryLabel: '',
        categoryName: '',
        categoryUrl: '',
    };
};

export const getCategoryLevels = (item) => {
    const collection: Array<CategoryLevel> = [];

    if (!item) {
        return collection;
    }

    collection.push({
        categoryId: item.l1CategoryId,
        categoryLabel: item.l1CategoryLabel,
        categoryName: item.l1CategoryName,
        categoryUrl: item.l1CategoryUrl,
    });

    if (item.l2CategoryId) {
        collection.push({
            categoryId: item.l2CategoryId,
            categoryLabel: item.l2CategoryLabel,
            categoryName: item.l2CategoryName,
            categoryUrl: item.l2CategoryUrl,
        });
    }

    if (item.l3CategoryId) {
        collection.push({
            categoryId: item.l3CategoryId,
            categoryLabel: item.l3CategoryLabel,
            categoryName: item.l3CategoryName,
            categoryUrl: item.l3CategoryUrl,
        });
    }

    if (item.l4CategoryId) {
        collection.push({
            categoryId: item.l4CategoryId,
            categoryLabel: item.l4CategoryLabel,
            categoryName: item.l4CategoryName,
            categoryUrl: item.l4CategoryUrl,
        });
    }

    return collection;
};

export const getCategoryLevelsString = (levels: any[]) => {
    let str = '';
    levels.forEach((l) => {
        str += `${l.categoryName} > `;
    });
    return str.substr(0, str.lastIndexOf('>'));
};
