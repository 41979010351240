type KameleoonBidderIdentificationFields = {
    bidderId: number;
    visitorCode: string;
};

export const getVisitorCodeOrBidderId = ({
    bidderId = 0,
    visitorCode = '',
}: KameleoonBidderIdentificationFields): string => {
    return bidderId > 0 ? String(bidderId) : visitorCode;
};
