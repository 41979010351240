import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '../core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export enum ItemCardNavigationTrigger {
    BidLiveClick = 'Bid Live Click',
    CountdownClick = 'Countdown Click',
    HouseNameClick = 'House Name Click',
    ImageClick = 'Image Click',
    PriceClick = 'Price Click',
    TitleClick = 'Title Click',
}

type TrackItemCardNavigationPayload = TrackingPayload & {
    houseId: number;
    itemId: number;
    trigger: ItemCardNavigationTrigger;
};

export const trackItemCardNavigationAnalytics = (payload: TrackItemCardNavigationPayload) => {
    track(TrackingEvents.Item.ItemCardClicked, payload);
};

export const useTrackItemCardNavigationAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        ({ houseId, itemId, trigger }: Omit<TrackItemCardNavigationPayload, 'pageName' | 'url'>) => {
            return trackItemCardNavigationAnalytics({
                houseId,
                itemId,
                pageName: getAmplitudePageNameFromPath(pathname),
                trigger,
                url,
            });
        },
        [pathname, url]
    );
};
