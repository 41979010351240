import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';

export enum TrackAuctionRegistrationSubmittedType {
    Auto = 'Auto',
    Bid = 'Bid',
    User = 'User',
}

type TrackAuctionRegistrationSubmittedPayload = TrackingPayload & {
    registrationType: TrackAuctionRegistrationSubmittedType;
};

export const trackAuctionRegistrationSubmittedAnalytics = (payload: TrackAuctionRegistrationSubmittedPayload) => {
    track(TrackingEvents.Registration.AuctionRegistrationSubmitted, payload);
};
