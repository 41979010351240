import { AccountDetailsEventFlow, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type AddShippingAddressViewedPayload = TrackingPayload & {
    eventFlow: AccountDetailsEventFlow;
};

const trackAddShippingAddressViewed = (payload: AddShippingAddressViewedPayload) => {
    track(TrackingEvents.Account.AddShippingAddressViewed, payload);
};

export const useTrackAddShippingAddressViewed = (eventFlow: AccountDetailsEventFlow) => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    return useCallback(() => {
        trackAddShippingAddressViewed({
            eventFlow,
            pageName: getAmplitudePageNameFromPath(pathname),
            url: getFullUrl(deployment, pathname),
        });
    }, [deployment, eventFlow, pathname]);
};
