import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackAuctioneerUnfollowedPayload = TrackingPayload;

export const trackAuctioneerUnfollowedAnalytics = (payload: TrackAuctioneerUnfollowedPayload) => {
    track(TrackingEvents.Auctioneer.AuctioneerUnfollowed, payload);
};

export const useTrackAuctioneerUnfollowedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        () =>
            trackAuctioneerUnfollowedAnalytics({
                pageName: getAmplitudePageNameFromPath(pathname),
                url,
            }),
        [pathname, url]
    );
};
