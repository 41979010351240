export type SellerDetail = {
    description: string;
    email: string;
    phoneNumber: string;
    sellerId: number;
    website: string;
};

export const EmptySellerDetail: SellerDetail = {
    description: '',
    email: '',
    phoneNumber: '',
    sellerId: 0,
    website: '',
};
