import { CatalogAddress } from '@/types/Catalog';
import { Seller } from '@/types/Seller';
import { translateAccents } from './translateCharacterAccents';
import CountriesWithISOCodes from '@liveauctioneers/caterwaul-components/lib/Intl/CountriesWithISOCodes';
import USStatesWithISOCodes from '@liveauctioneers/caterwaul-components/lib/Intl/USStatesWithISOCodes';

export const isFromUS = (country?: string) => {
    if (!country) {
        return false;
    }
    const lower = country.toLowerCase().replace(/\s+/g, '').trim();
    return lower === 'unitedstates' || lower === 'usa' || lower === 'us';
};

export const translateAddressAccents = (form: any) => {
    if (!form) {
        return form;
    }
    return {
        address: translateAccents(form.address),
        address1: translateAccents(form.address1),
        address2: translateAccents(form.address2),
        city: translateAccents(form.city),
        country: translateAccents(form.country),
        firstName: translateAccents(form.firstName),
        lastName: translateAccents(form.lastName),
        mobile: translateAccents(form.mobile),
        mobileNumber: translateAccents(form.mobileNumber),
        organizationName: translateAccents(form.organizationName),
        state: translateAccents(form.state),
        zip: translateAccents(form.zip),
        zipcode: translateAccents(form.zipcode),
    };
};

export const getAddressForCatalog = (catalogAddress: CatalogAddress, seller: Seller) => {
    if (catalogAddress?.address1) {
        const {
            address1 = '',
            address2 = '',
            city = '',
            country = 'US',
            fullCountryName = 'United States',
            lat,
            lng,
            postalCode = '',
            state = '',
        } = catalogAddress;
        return {
            address1,
            address2,
            city: city?.trim() || '',
            country,
            fullCountryName,
            lat,
            lng,
            postalCode,
            state,
        } as CatalogAddress;
    } else {
        const {
            address = '',
            address2 = '',
            city = '',
            country = 'United States',
            countryCode = 'US',
            stateCode = '',
            zip,
        } = seller || {};
        const { lat, lng } = catalogAddress || {};
        return {
            address1: address,
            address2,
            city: city?.trim() || '',
            country: countryCode,
            fullCountryName: country,
            lat,
            lng,
            postalCode: zip,
            state: stateCode,
        } as CatalogAddress;
    }
};

export const getBidderReviewLocation = (city = '', state = '', country = '') => {
    const newCity =
        city.length > 3
            ? (' ' + city)
                  .toLowerCase()
                  .replace(/ (\w)/g, (str) => {
                      return str.toUpperCase();
                  })
                  .trim()
            : city.toUpperCase();
    const newState =
        state.length > 3
            ? (' ' + state)
                  .toLowerCase()
                  .replace(/ (\w)/g, (str) => {
                      return str.toUpperCase();
                  })
                  .trim()
            : state.toUpperCase();
    const newCountry =
        country.length > 3
            ? (' ' + country)
                  .toLowerCase()
                  .replace(/ (\w)/g, (str) => {
                      return str.toUpperCase();
                  })
                  .trim()
            : country.toUpperCase();

    let returnString = '';
    if (isFromUS(newCountry) && Boolean(newCity) && Boolean(newState)) {
        returnString = `${newCity}, ${newState}`;
    } else if (Boolean(newCity) && Boolean(newCountry)) {
        returnString = `${newCity}, ${newCountry}`;
    }
    return returnString;
};

export const get2DigitCountryCode = (country: string): string => {
    if (!Boolean(country) || country.length === 2) {
        return country?.toUpperCase();
    }
    const newCountry = CountriesWithISOCodes.find(
        (c) =>
            c.code === country.toUpperCase() ||
            c.code3 === country.toUpperCase() ||
            c.name.toUpperCase() === country.toUpperCase()
    );
    if (!Boolean(newCountry)) {
        return country;
    }
    return newCountry?.code || '';
};

export const getCountryName = (country: string): string => {
    if (!Boolean(country) || country.length > 3) {
        return country;
    }
    const newCountry = CountriesWithISOCodes.find(
        (c) =>
            c.code === country.toUpperCase() ||
            c.code3 === country.toUpperCase() ||
            c.name.toUpperCase() === country.toUpperCase()
    );
    if (!Boolean(newCountry)) {
        return country;
    }
    return newCountry?.name || '';
};

export const getStateName = (state: string): string => {
    if (!Boolean(state) || state.length > 2) {
        return state;
    }
    const newState = USStatesWithISOCodes.find(
        (c) => c.code === state.toUpperCase() || c.name.toUpperCase() === state.toUpperCase()
    );
    if (!Boolean(newState)) {
        return state;
    }
    return newState?.name || '';
};
