import { TrackingPayload } from '@/utils/analytics/core.types';

/**
 * Returns a new object with field names converted from camelCase to human-readable names
 * NOTE: You shouldn't need to use this outside of the analytics/core.ts file, since it's automatically called for all outgoing Amplitude tracking events
 * Example: `bidAmount` -> `Bid Amount`
 * Example: `CTAName` -> `CTA Name`
 * @param payload The object to be converted
 */
export const convertPayloadToHumanReadableFieldNames = (payload: TrackingPayload) => {
    let humanReadable: unknown = {};
    Object.keys(payload).forEach((key) => {
        // First handle acronyms by not splitting consecutive capital letters
        const withAcronyms = key.replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2');
        // Then handle regular camelCase
        const withSpaces = withAcronyms.replace(/([a-z\d])([A-Z])/g, '$1 $2');
        const capitalized = `${withSpaces.charAt(0).toUpperCase()}${withSpaces.slice(1)}`;
        humanReadable[capitalized] = payload[key];
    });
    return humanReadable;
};
