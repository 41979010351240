import { AccountDetailsEventFlow, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackAddPaymentMethodViewedPayload = TrackingPayload & {
    eventFlow: AccountDetailsEventFlow;
};

const trackAddPaymentMethodViewed = (payload: TrackAddPaymentMethodViewedPayload) => {
    track(TrackingEvents.AuthModal.AddPaymentMethodViewed, payload);
};

export const useTrackAddPaymentMethodViewed = (eventFlow: AccountDetailsEventFlow) => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(() => {
        trackAddPaymentMethodViewed({
            eventFlow,
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
        });
    }, [eventFlow, pathname, url]);
};
