export type CoverLot = {
    imageVersion: number;
    itemId: number;
    title: string;
    url: string;
};

export const EmptyCoverLot = {
    imageVersion: 0,
    itemId: 0,
    title: '',
    url: '',
};
