import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackAuctioneerFollowedPayload = TrackingPayload;

export const trackAuctioneerFollowedAnalytics = (payload: TrackAuctioneerFollowedPayload) => {
    track(TrackingEvents.Auctioneer.AuctioneerFollowed, payload);
};

export const useTrackAuctioneerFollowedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        () =>
            trackAuctioneerFollowedAnalytics({
                pageName: getAmplitudePageNameFromPath(pathname),
                url,
            }),
        [pathname, url]
    );
};
