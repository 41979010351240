export type BidderPreference = {
    bidderId: string;
    created: string;
    modified: string;
    preferredCurrency: string;
};

export const emptyBidderPreference: BidderPreference = {
    bidderId: '0',
    created: '',
    modified: '',
    preferredCurrency: '',
};
