export const cleanSearchTerms = (terms: any = '', orsToCommas: boolean = false) => {
    if (!terms || typeof terms !== 'string') {
        return '';
    }
    if (!terms.trim()) {
        return '';
    }
    if (orsToCommas) {
        return terms.replace(/\\"/g, '').replace(',', '').replace(/ OR/g, ',');
    }
    return terms.replace(/\\"/g, '').trim();
};

/**
 * cleanKeywordSearchString - returns a keyword search string with
 * single-character words, extra spaces and special characters filtered.
 * @param keyword
 * @returns string
 */
export const cleanKeywordSearchString = (keyword: string): string => {
    let cleanedKeyword: string = '';

    const keywords = cleanKeywordCharacters(keyword)
        .split(/\s+/g)
        .filter((word: string) => word.length > 1);

    if (keywords.length > 0) {
        cleanedKeyword = keywords.join(' ');
    }
    return cleanedKeyword;
};

/**
 * cleanKeywordCharacters - cleans up search string keyword with invalid keyboard characters
 * currently only used when creating a save search from an item title on new signup
 * @param keyword string to filter
 * @returns string filtered string
 * only filters special US-EN keyboard available characters, preserving - only
 * all other characters are replaced with a space, duplicate spaces are removed and return string is trimmed
 */
export const cleanKeywordCharacters = (keyword: string = ''): string => {
    return keyword
        .replace(/[^-A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]+/g, ' ')
        .replace('.', ' ')
        .replace(/\s+/g, ' ')
        .trim();
};

export default cleanSearchTerms;
