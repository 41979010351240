export enum AuctionStatus {
    Done = 'done',
    Ended = 'ended',
    Live = 'live',
    NotLoaded = 'notLoaded',
    Online = 'online',
    Paused = 'paused',
    Unknown = 'unknown',
    Upcoming = 'upcoming',
}

export enum CurrentLotStatus {
    Closed = 'closed',
    Open = 'open',
    Unknown = 'unknown',
}
