import { AnalyticsCatalogRegistrationStatus, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAnalyticsRegistrationStatus } from '@/utils/catalog/getAnalyticsRegistrationStatus';
import { getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { getItemFacets } from '@/redux/modules/itemFacets';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getUrlFromPathname } from '@/redux/modules/config';
import { getUserPrebidStatus } from '@/utils/analytics/bidding/biddingAnalytics.utils';
import { track } from '@/utils/analytics/core';
import { TrackBidPlacedPrebidStatus } from '@/utils/analytics/bidding/biddingAnalytics.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackPlaceBidPressedPayload = TrackingPayload & {
    auctionRegistrationStatus: AnalyticsCatalogRegistrationStatus;
    isLoggedIn: boolean;
    lotCategoryLvl1: string;
    lotCategoryLvl2: string;
    userPrebidStatus: TrackBidPlacedPrebidStatus;
};

export const trackPlaceBidPressedAnalytics = (payload: TrackPlaceBidPressedPayload) => {
    track(TrackingEvents.Bidding.PlaceBidPressed, payload);
};

export const useTrackPlaceBidPressedAnalytics = (catalogId: number, itemId: number) => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));
    const bidderId = useAppSelector(getBidderId);
    const catalogRegistration = useAppSelector((state) => getCatalogRegistration(state, catalogId));
    const { categories } = useAppSelector((state) => getItemFacets(state, itemId));
    const { bidderHasBid, bidderHasHighBid, isReserveMet } = useAppSelector((state) => getItemSummary(state, itemId));

    return useCallback(() => {
        trackPlaceBidPressedAnalytics({
            auctionRegistrationStatus: getAnalyticsRegistrationStatus(catalogRegistration),
            isLoggedIn: bidderId > 0,
            lotCategoryLvl1: categories?.[0]?.l1CategoryLabel ?? 'Unknown',
            lotCategoryLvl2: categories?.[0]?.l2CategoryLabel ?? 'Unknown',
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
            userPrebidStatus: getUserPrebidStatus(bidderHasBid, bidderHasHighBid, isReserveMet),
        });
    }, [bidderHasBid, bidderHasHighBid, bidderId, catalogRegistration, categories, isReserveMet, pathname, url]);
};
