import { ShippingMethods } from '@/enums/shippingMethod';
import { ShippingProviderId } from '@/enums/shippingProviders';

export type ShippingQuote = {
    currency: string;
    link: string;
    message: string;
    quoteId: string;
    quoteNumber: string;
    shippingAvailable: boolean;
    shippingMethod?: ShippingMethods;
    shippingProvider: ShippingProviderId;
    shippingStatus: string;
    shippingStatusSecondary: string;
    shippingTrackingLink?: string;
    statusTerminal: boolean;
    total: string;
    transitDays: number;
};

export const emptyCheckoutShippingQuote: ShippingQuote = {
    currency: '',
    link: '',
    message: '',
    quoteId: '',
    quoteNumber: '',
    shippingAvailable: false,
    shippingProvider: 0,
    shippingStatus: '',
    shippingStatusSecondary: '',
    shippingTrackingLink: '',
    statusTerminal: false,
    total: '',
    transitDays: 0,
};
