import { concatenatePath, findCategoryContainingSomeCategoryId, translateStatus } from './searchUtils';
import { DEFAULT_SORT_ORDER } from '@/redux/modules/search/search.types';
import { getCategoryNameFromPathname, isSimilarItemsPage } from '@/utils/urls';
import { getFacetFromSearchQueryParameter } from '../queryParams';
import { SearchQuery } from '@/types/search/searchQuery';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import FacetTypes from '../../types/search/enums/facetTypes';
import paramTypes from '@/types/search/enums/paramTypes';
import reject from 'lodash/reject';

interface BuildSearchQueryParams {
    catalogId?: number | undefined;
    defaultSortOrder?: SearchSortAndDirection;
    pathname?: string;
    // TODO type search params eventually / remove
    queryParams?: {
        [key: string]: any;
        sort?: SearchSortAndDirection;
    };
    rawFacets?: any[];
    searchPageSize?: number;
    similarItems?: boolean;
}

/**
 * buildSearchQuery - constructs a search-party query object
 * which is mostly a 1-to-1 mapping with Algolia queries, with some
 * curating for exclusion parameters and target indexes (sold vs upcoming)
 * @param BuildSearchQueryParams options
 * @return SearchQuery
 */
export const buildSearchQuery = ({
    catalogId,
    defaultSortOrder = DEFAULT_SORT_ORDER,
    pathname = '/search',
    queryParams = {},
    rawFacets = [],
    searchPageSize = 200,
    similarItems = false,
}: BuildSearchQueryParams): SearchQuery => {
    if (pathname?.includes('price-guide')) {
        queryParams.keyword = getCategoryNameFromPathname(pathname);
    }
    const similarItemsPage = isSimilarItemsPage(pathname);
    const status = translateStatus(queryParams, pathname);
    const searchQuery: SearchQuery = {
        analyticsTags: ['web'],
        buyNow: queryParams.buyNow,
        buyNowDate: queryParams.buyNowDate,
        campaignId: queryParams.campaign_id,
        categories: [],
        distance: {},
        domesticFlatShipping: queryParams.domesticFlatShipping,
        options: { status },
        page: queryParams.page || 1,
        pageSize: searchPageSize,
        publishDate: {},
        ranges: {},
        saleDate: {},
        searchTerm: queryParams.keyword ? queryParams.keyword : '',
        ...(similarItems && { similarItems: true }),
        sort: queryParams.sort || defaultSortOrder,
    };

    if (similarItemsPage) {
        searchQuery.analyticsTags.push('similar-items');
    }

    if (catalogId) {
        searchQuery.catalogId = catalogId;
    }

    const categoryIds = queryParams.category_id;
    if (categoryIds) {
        const treeFacets = rawFacets.filter((f) => f.id === FacetTypes.CATEGORY_ID || f.id === FacetTypes.ORIGIN_ID);
        const flatCategoryFacets = reject(
            rawFacets,
            (f) =>
                f.id === FacetTypes.CATEGORY_ID ||
                f.id === FacetTypes.ORIGIN_ID ||
                !f.options ||
                f.id === FacetTypes.AUCTION_HOUSE_ID
        );

        categoryIds
            .toString()
            .split(',')
            .forEach((categoryId) => {
                const facetOption = flatCategoryFacets.find((facet) =>
                    facet.options.some((option) => option.id === categoryId)
                );
                if (facetOption) {
                    if (!searchQuery.options[facetOption.id]) {
                        searchQuery.options[facetOption.id] = [];
                    }
                    searchQuery.options[facetOption.id].push(categoryId);
                } else {
                    const rootCategory = findCategoryContainingSomeCategoryId(categoryId, treeFacets);
                    searchQuery?.categories?.push(concatenatePath(rootCategory, categoryId));
                }
            });
    }

    Object.keys(queryParams).forEach((key) => {
        if (
            key === paramTypes.PAGE ||
            key === paramTypes.PAGE_SIZE_LEGACY_SUPPORT ||
            key === paramTypes.PAGE_SIZE ||
            key === paramTypes.CATEGORY_ID ||
            key === paramTypes.KEYWORD ||
            key === paramTypes.SORT ||
            key === paramTypes.STATUS ||
            key === paramTypes.CAMPAIGN_ID ||
            key === paramTypes.BUY_NOW ||
            key === paramTypes.BUY_NOW_DATE
        ) {
            return;
        }

        const convertedKey = getFacetFromSearchQueryParameter(key);
        const isRangeFacet =
            convertedKey === FacetTypes.ESTIMATED_PRICE_ID ||
            convertedKey === FacetTypes.CURRENT_BID_ID ||
            convertedKey === FacetTypes.HAMMER_PRICE_ID ||
            convertedKey === FacetTypes.RESERVE;
        const isDistanceFacet = convertedKey === FacetTypes.DISTANCE_ID;
        if (isRangeFacet && searchQuery?.ranges) {
            const minMaxMatches = queryParams[key].match(/-?\d+/g);
            if (!searchQuery?.ranges[convertedKey]) {
                searchQuery.ranges[convertedKey] = {};
            }
            const minValue = Number(minMaxMatches[0]);
            const maxValue = Number(minMaxMatches[1]);
            searchQuery.ranges[convertedKey].min = minValue === -1 ? undefined : minValue;
            searchQuery.ranges[convertedKey].max = maxValue === -1 ? undefined : maxValue;
        } else if (isDistanceFacet && queryParams[key] && queryParams[key].split) {
            const distanceOptions = queryParams[key].split(':');
            searchQuery.distance.country = distanceOptions[0];
            searchQuery.distance.zipCode = distanceOptions[1];
            searchQuery.distance.space = Number(distanceOptions[2]);
            searchQuery.distance.measurement = distanceOptions[3];
        } else if (key === paramTypes.PUBLISH_DATE_MIN) {
            searchQuery.publishDate.min = Number(queryParams[key]);
            searchQuery.publishDate.minSet = true;
        } else if (key === paramTypes.PUBLISH_DATE_MAX) {
            searchQuery.publishDate.max = Number(queryParams[key]);
            searchQuery.publishDate.maxSet = true;
        } else if (key === paramTypes.SALE_DATE_MIN) {
            searchQuery.saleDate.min = Number(queryParams[key]);
            searchQuery.saleDate.minSet = true;
        } else if (key === paramTypes.SALE_DATE_MAX) {
            searchQuery.saleDate.max = Number(queryParams[key]);
            searchQuery.saleDate.maxSet = true;
        } else if (key === paramTypes.FILTER_TERM) {
            searchQuery.filterTerm = queryParams[key].split(',').join(' AND ');
        } else if (queryParams[key] && queryParams[key].split) {
            searchQuery.options[convertedKey] = queryParams[key].split(',');
        } else if (key === paramTypes.SHIPPING) {
            searchQuery.options[key] = [queryParams.shipping];
        }
    });

    return searchQuery;
};

export const isBuyNowQuery = (searchQuery: { options: any; sort: SearchSortAndDirection | undefined }) => {
    return Boolean(
        searchQuery.sort !== SearchSortAndDirection.TimeMostRecent &&
            searchQuery.options &&
            searchQuery.options['sale_type'] &&
            searchQuery.options['sale_type'].includes('BuyNow')
    );
};
