import { getCategoryFacetLink, getSearchUrlWithParams } from '@/utils/urls';
import { IntlShape } from '@liveauctioneers/hammer-ui-core/intl';
import cleanSearchTerms from '@/utils/cleanSearchTerms';
import compact from 'lodash/compact';
import union from 'lodash/union';
import type { Facet, FacetValue } from '@/types/SearchFacet';

interface ToFacetLabelProps {
    facet: Facet;
    intl: IntlShape;
    value?: FacetValue;
}

const toFacetLabel = ({ facet, intl, value }: ToFacetLabelProps): string => {
    const { formatMessage, formatNumber } = intl;
    const selectionType = facet.selection;
    if (selectionType === 'single') {
        return value?.name || '';
    }
    if (selectionType === 'single2') {
        return value?.name || '';
    }
    if (selectionType === 'distance') {
        return `${facet.name}: ${formatNumber(
            facet?.selected_radius || 0
        )} ${facet?.selected_radius_unit?.substring(0, 2)} ${formatMessage({
            id: 'savedSearches.within',
        })} ${facet.selected_postal_code}`;
    }
    if (selectionType === 'range') {
        if (Number(facet.selected_start) < 1) {
            return `${facet.name}: ${formatMessage({
                id: 'savedSearches.atMost',
            })} ${formatNumber(Number(facet.selected_end), {
                currency: String(facet?.currencies?.[0]) || 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                style: 'currency',
            })}`;
        } else if (Number(facet.selected_end) < 1) {
            return `${facet.name}: ${formatMessage({
                id: 'savedSearches.atLeast',
            })} ${formatNumber(Number(facet.selected_start), {
                currency: String(facet?.currencies?.[0]) || 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                style: 'currency',
            })}`;
        } else {
            return `${facet.name}: ${formatNumber(Number(facet.selected_start), {
                currency: String(facet?.currencies?.[0]) || 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                style: 'currency',
            })} - ${formatNumber(Number(facet.selected_end), {
                currency: String(facet?.currencies?.[0]) || 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                style: 'currency',
            })}`;
        }
    }
    if (selectionType === 'multiple') {
        return value?.name || '';
    }
    if (selectionType === 'keyword') {
        return facet.selected_value ? facet.selected_value : '';
    }
    if (selectionType === 'filterTerm' && facet.qs === 'filterTerm') {
        return `${facet.name}: ${facet?.selected_value?.replace(',', ', ')}`;
    }
    if (selectionType === 'filterTerm') {
        return facet.name;
    }
    if (selectionType === 'date') {
        return facet.name;
    }

    return '';
};

export const toFacetString = (facets: Facet[], intl: IntlShape) => {
    const selections: string[] = [];
    facets.forEach((facet: Facet) => {
        if (facet.qs === 'keyword') {
            selections.unshift(toFacetLabel({ facet, intl }));
        } else if (facet.qs === 'status') {
        } else if (facet.values) {
            facet.values.forEach((value: FacetValue) => {
                selections.push(toFacetLabel({ facet, intl, value }));
            });
        } else {
            selections.push(toFacetLabel({ facet, intl }));
        }
    });
    return compact(selections).join(', ');
};

export const toFacetLabelPairs = (facets: Facet[], intl: IntlShape) => {
    const selections: string[] = [];
    facets.forEach((facet: Facet) => {
        if (facet.qs === 'keyword') {
            facet.selected_value && selections.unshift(cleanSearchTerms(toFacetLabel({ facet, intl })));
        } else if (facet.qs === 'status') {
        } else if (facet.values) {
            facet.values.forEach((value: FacetValue) => {
                selections.push(toFacetLabel({ facet, intl, value }));
            });
        } else {
            selections.push(toFacetLabel({ facet, intl }));
        }
    });
    return selections;
};

const toFacetUrlParam = (facet: Facet, oldValue?: string) => {
    const selectionType = facet.qs;
    if (facet.selection === 'single' || facet.selection === 'multiple') {
        const oldValues = oldValue ? oldValue.split(',') : [];
        const newValues = facet?.values?.map((x) => `${x.value}`);
        return union(oldValues, newValues).join(',');
    }
    if (selectionType === 'keyword' || selectionType === 'filterTerm') {
        return facet.selected_value; // overwrite old value
    }
    if (facet.selection === 'range') {
        const oldValues = oldValue ? oldValue.split(',') : [];

        const currency = facet?.currencies?.length ? facet?.currencies?.[0] : 'USD';
        const min = facet.selected_start;
        const max = facet.selected_end;
        const minValue = min !== '' && min !== undefined ? min : -1;
        const maxValue = max !== '' && max !== undefined ? max : -1;
        const newValue = `${currency}:${minValue}:${maxValue}`;

        return union(oldValues, [newValue]).join(',');
    }
    if (facet.selection === 'distance') {
        const oldValues = oldValue ? oldValue.split(',') : [];

        const country = facet.selected_country;
        const zipCode = facet.selected_postal_code;
        const range = facet.selected_radius;
        const measurement = facet.selected_radius_unit;
        const newValue = `${country}:${zipCode}:${range}:${measurement}`;

        return union(oldValues, [newValue]).join(',');
    }
};

export const toFacetUrlParams = (facets: Facet[]) => {
    const url = {
        // published_date: null,
        // sort: '-publish_date',
    };
    facets.forEach((facet) => {
        const value = toFacetUrlParam(facet, url[facet.qs]);
        if (value) {
            url[facet.qs] = value;
        }
    });
    return url;
};

export const toSearchUrl = (facets: Facet[]) => {
    // TODO why does typescript think this is empty
    const urlParams = toFacetUrlParams(facets);
    return getSearchUrlWithParams(urlParams);
};

/**
 * toSavedSearchUrl checks incoming facets tree returns a
 * category search URL if Category facet is found and keyword is empty
 * otherwise returns normal search URL if keyword is present
 * @param facets
 * @returns string
 */
export const toSavedSearchUrl = (facets: Facet[]): string => {
    const categoryFacetId = facets.reduce((acc: string, facet: Facet) => {
        if (facet.name === 'Category') {
            const parts = facet.id.match(/\/(\d+)/);
            return parts?.[1] ? encodeURI(facet?.values?.[0]?.name?.toLowerCase() || '') + '/' + parts[1] : acc;
        }
        return acc;
    }, '');
    const searchKeyword = facets.reduce((acc: string, facet: Facet) => {
        return facet.id === 'keyword' ? facet.selected_value : acc;
    }, '');

    if (categoryFacetId && !searchKeyword) {
        const filteredFacets = facets.filter((facet: Facet) => facet.id !== 'keyword' && facet.name !== 'Category');
        return getCategoryFacetLink(categoryFacetId, toFacetUrlParams(filteredFacets));
    }
    const searchUrl = toSearchUrl(facets);
    return searchUrl;
};
