import { FilterAction } from '@/types/SearchFacet';
import { parseQueryParams } from '@/utils/queryParams';

export type AmplitudeSearchQueryParams = {
    categoryId?: string[];
    country?: string;
    currentBid?: string;
    distance?: string;
    estimatedPrice?: string;
    filterAction?: FilterAction;
    filterTerms?: string[];
    hammerPrice?: string;
    houseId?: string;
    isTopRated?: boolean;
    page?: number;
    pageSize?: number;
    saleType?: string[];
    searchKeywords: string;
    shipping?: string[];
    sort?: string;
    status?: string;
};

export const createAmplitudeSearchQueryParams = (
    searchString: string,
    pageSize: number
): AmplitudeSearchQueryParams => {
    const queryParams = parseQueryParams(searchString);

    const {
        category_id = '',
        country = 'US',
        current_bid = '',
        distance = '',
        estimated_price = '',
        filterTerm = '',
        hammer_price = '',
        house_id = '',
        keyword = '',
        page = 1,
        sale_type = '',
        shipping = '',
        sort = '',
        status = '',
        topRated = '',
    } = queryParams;

    const convertStringToArray = (stringValue: string) => {
        if (!Boolean(stringValue)) {
            return [];
        }
        return stringValue
            .split(',')
            .map((term) => term.trim())
            .filter(Boolean)
            .sort();
    };

    return {
        categoryId: convertStringToArray(category_id),
        country,
        currentBid: current_bid,
        distance,
        estimatedPrice: estimated_price,
        filterTerms: convertStringToArray(filterTerm),
        hammerPrice: hammer_price,
        houseId: house_id,
        isTopRated: Boolean(topRated),
        page,
        pageSize,
        saleType: convertStringToArray(sale_type),
        searchKeywords: keyword,
        shipping: convertStringToArray(shipping),
        sort,
        status,
    };
};
