import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackHeaderCategoryClickedPayload = TrackingPayload & {
    categoryName: string;
};

export const trackHeaderCategoryClickedAnalytics = (payload: TrackHeaderCategoryClickedPayload) => {
    track(TrackingEvents.GlobalNavigation.HeaderCategoryClicked, payload);
};

export const useTrackHeaderCategoryClickedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        ({ categoryName }: Omit<TrackHeaderCategoryClickedPayload, 'pageName' | 'url'>) =>
            trackHeaderCategoryClickedAnalytics({
                categoryName,
                pageName: getAmplitudePageNameFromPath(pathname),
                url,
            }),
        [pathname, url]
    );
};
