export type BuyersPremium = {
    high: number | null | undefined;
    low: number;
    lowCutoff: number | null | undefined;
    middle: number | null | undefined;
    middleCutoff: number | null | undefined;
};

export const EmptyBuyersPremium = {
    high: undefined,
    low: 0,
    lowCutoff: undefined,
    middle: undefined,
    middleCutoff: undefined,
};
