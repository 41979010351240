import { Curator } from './Curator';

export type CustomTerm = {
    data: string;
    id: string;
    title: string;
};

export const emptyCustomTerm: CustomTerm = {
    data: '',
    id: '',
    title: '',
};

export type CatalogDetail = {
    bidderArrangedShipping: boolean;
    catalogId: number;
    containsBuyNowItems?: boolean;
    curators: Curator[];
    customTerms: CustomTerm[];
    externalUrl?: string;
    freeLocalPickup: boolean;
    hasWeapons?: boolean;
    inHouseShipping: boolean;
    paymentDetails: string;
    rtmpSecret?: string;
    rtmpStream?: string;
    rtspHost?: string;
    rtspPort?: number;
    rtspSuffix?: string;
    shippingDetails: string;
    streamId?: number;
    terms: string;
};

export const emptyCatalogDetail: CatalogDetail = {
    bidderArrangedShipping: false,
    catalogId: 0,
    curators: [],
    customTerms: [],
    freeLocalPickup: false,
    inHouseShipping: false,
    paymentDetails: '',
    shippingDetails: '',
    terms: '',
};
