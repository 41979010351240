import { Deployment } from '@liveauctioneers/hammer-ui-payments/types';
import { getDeployment } from '@/redux/modules/config';
import { useLocation } from 'react-router-dom';

export const getFullUrl = (
    deployment: ReturnType<typeof getDeployment>,
    pathname: ReturnType<typeof useLocation>['pathname']
) => {
    let deploymentDomain: string;
    switch (deployment) {
        case Deployment.Barako:
            deploymentDomain = 'bidder-react-barako.liveauctioneers.com';
            break;
        case Deployment.Stage:
            deploymentDomain = 'bidder-react-stage.liveauctioneers.com';
            break;
        case Deployment.PreProd:
            deploymentDomain = 'bidder-react-preprod.liveauctioneers.com';
            break;
        case Deployment.Prod:
        default:
            deploymentDomain = 'www.liveauctioneers.com';
            break;
    }
    return `${deploymentDomain}${pathname}`;
};
