export enum TrackTwoFactorAuthentication {
    ModalViewed = '2FA Modal Viewed',
    OTPCodeAccepted = '2FA OTP Code Accepted',
    OTPCodeResent = 'Resent 2FA OTP Code requested',
    OTPCodeSubmitted = 'Submitted 2FA OTP Code',
}

export enum TrackTwoFactorAuthenticationTryAnotherWay {
    ButtonPressed = '2FA Another Way',
    SentByEmail = 'Resent 2FA OTP Code requested',
    SentBySms = 'Resent 2FA OTP SMS request',
}
