import { jwtDecode } from 'jwt-decode';
import { UserRegistrationStatus } from '@/redux/modules/account/user/user.types';

export type JWTPayload = {
    exp: number;
    iat: number;
    iss: string;
    sub: string;
    type: UserRegistrationStatus;
};

export const validateBidderId = (id: number) => {
    if (typeof id !== 'number' || isNaN(id) || !id || id < 1) {
        return -1;
    }
    return id;
};

/**
 * validateAuthToken
 * @param token
 * @returns a validated auth token or an empty string if the current token is invalid or expired
 */
const validateAuthToken = (token: string = ''): string => {
    // if there is no token, then shortcircuit
    if (!Boolean(token)) {
        return token;
    }
    // if we have a token, then decode it and see if it's still valid
    try {
        const { exp, sub, type } = jwtDecode<JWTPayload>(token);
        const bidderId = validateBidderId(Number(sub));
        // if the token has expired, or the bidderId is invalid or the type of the token is not bidder, then return an empty string
        if (
            Date.now() > exp * 1000 ||
            bidderId < 1 ||
            (type !== UserRegistrationStatus.Registered && type !== UserRegistrationStatus.Partially)
        ) {
            return '';
        }
        return token;
    } catch {
        return '';
    }
};

export default validateAuthToken;

// given an auth token, returns the bidderId or zero if the token is invalid
export const getBidderIdFromAuthToken = (token: string): number => {
    const validatedToken = validateAuthToken(token);

    if (!validatedToken) {
        return 0;
    }

    try {
        const decoded = jwtDecode<JWTPayload>(validatedToken);

        if (decoded.type === 'bidder') {
            const validatedBidderId = validateBidderId(Number(decoded.sub));

            if (validatedBidderId > 0) {
                return validatedBidderId;
            } else {
                return 0;
            }
        }
    } catch (error) {
        console.warn('Failed to decode a potentially valid token', error, token);
    }

    return 0;
};
