import { Distance } from '@/types/Distance';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

export enum SearchQueryStatus {
    Done = 'done',
    Live = 'live',
    Online = 'online',
    Passed = 'passed',
    Sold = 'sold',
    Upcoming = 'upcoming',
}

export type SearchQueryOptions = {
    auctionHouse?: string[];
    shipping?: [string];
    status: SearchQueryStatus[];
    topRated?: boolean;
};

export type SearchQueryDateRange = {
    max?: number;
    maxSet?: boolean;
    min?: number;
    minSet?: boolean;
};

export type SearchQuery = {
    analyticsTags: string[];
    buyNow: boolean | undefined;
    buyNowDate: number | undefined;
    campaignId: string | undefined;
    catalogId?: number;
    categories?: string[];
    distance: Distance;
    domesticFlatShipping: boolean | undefined;
    filterTerm?: string;
    fullSearch?: boolean;
    lat?: string;
    long?: string;
    options: SearchQueryOptions;
    page: number;
    pageSize: number;
    promoted?: boolean;
    publishDate: SearchQueryDateRange;
    ranges?: { [key: string]: SearchQueryDateRange };
    saleDate: SearchQueryDateRange;
    searchTerm: string;
    similarItems?: boolean;
    sort: SearchSortAndDirection;
    topRated?: boolean;
    useGeo?: boolean;
};

export type AugmentedSearchQueryOption = {
    exclude: string[];
    include: string[];
};

export type AugmentedSearchQueryOptions = {
    auctionHouse?: AugmentedSearchQueryOption[];
    shipping?: [string];
    status: SearchQueryStatus[];
    topRated?: boolean;
};

export type AugmentedSearchQuery = {
    analyticsTags: string[];
    buyNow: boolean;
    buyNowDate: number;
    campaignId: string;
    catalogId?: number;
    categories?: string[];
    distance: Distance;
    domesticFlatShipping: boolean;
    filterTerm?: string;
    fullResults?: boolean;
    lat?: string;
    long?: string;
    options: AugmentedSearchQueryOptions;
    page: number;
    pageSize: number;
    promoted?: boolean;
    publishDate: SearchQueryDateRange;
    ranges?: { [key: string]: SearchQueryDateRange };
    saleDate: SearchQueryDateRange;
    searchTerm: string;
    sort: string;
    topRated?: boolean;
    useGeo?: boolean;
};
