import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getPageFromQueryParams, parseQueryParams } from '@/utils/queryParams';
import { getUrlFromPathname } from '@/redux/modules/config';
import { SearchCompletedTrigger, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export enum TrackSearchCompletedSearchType {
    Auctioneer = 'Auctioneer',
    Keyword = 'Keyword',
}

type TrackSearchCompletedPayload = TrackingPayload & {
    numberOfSearchResults: number;
    page: number;
    pageSize: number;
    searchKeywords: string;
    searchSuggestionUsed: boolean;
    searchType: TrackSearchCompletedSearchType;
    trigger: SearchCompletedTrigger;
};

export const trackSearchCompletedAnalytics = (payload: TrackSearchCompletedPayload) => {
    track(TrackingEvents.Search.SearchCompleted, payload);
};

export const useTrackSearchCompletedAnalytics = () => {
    const { pathname, search } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));
    const queryParams = parseQueryParams(search);
    const page = getPageFromQueryParams(queryParams);

    return useCallback(
        ({
            numberOfSearchResults,
            pageSize,
            searchKeywords,
            searchSuggestionUsed,
            searchType,
            trigger,
        }: Omit<TrackSearchCompletedPayload, 'pageName' | 'url' | 'page'>) => {
            trackSearchCompletedAnalytics({
                numberOfSearchResults,
                page,
                pageName: getAmplitudePageNameFromPath(pathname),
                pageSize,
                searchKeywords,
                searchSuggestionUsed,
                searchType,
                trigger,
                url,
            });
        },
        [page, pathname, url]
    );
};
