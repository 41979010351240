import { Location } from 'react-router-dom';

type Action = 'PUSH' | 'POP' | 'REPLACE';

/** @deprecated Don't use history inside redux */
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE_custom' as const;

// I didn't see a simple way of getting this type out of the redux-first-history package, so I recreated it here
/** @deprecated Don't use history inside redux */
export type LocationChangeAction = {
    payload: { action: Action; location: Location };
    type: typeof ROUTER_LOCATION_CHANGE;
};
