import { BuyersPremium, EmptyBuyersPremium } from '@/types/BuyersPremium';

const toFixedFloat = (input) => parseFloat(input.toFixed(2));

export const calculateBuyersPremium = (bidAmount: number, buyersPremium: BuyersPremium) => {
    const { high, low, lowCutoff, middle, middleCutoff } = buyersPremium || EmptyBuyersPremium;

    let percentage = Number(low) / 100;
    if (middle !== null && middle !== 0 && lowCutoff !== null && lowCutoff !== 0 && bidAmount >= lowCutoff) {
        percentage = Number(middle) / 100;
    }

    if (high !== null && high !== 0 && middleCutoff !== null && middleCutoff !== 0 && bidAmount >= middleCutoff) {
        percentage = Number(high) / 100;
    }

    return {
        amount: Number(toFixedFloat(bidAmount * percentage)),
        percentage: Number(toFixedFloat(percentage * 100)),
    };
};
