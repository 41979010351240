/**
 * This function makes setting default values of an object easier
 *
 * Explanation of the function
 * ```
 * nullCoalesceSameStructure(a,b,c,'d','e') === a['d']['e'] = b['d']['e'] ?? c['d']['e'];
 * ```
 *
 * @see `src/redux/modules/walkthroughs/walkthroughs.reducer.ts`
 * @param keys List of keys that equal the path taken in all three objects.
 * @returns The value that was set to `objectToSetPropertyOf`
 */
export const nullCoalesceSameStructure = <T extends Object>(
    objectToSetPropertyOf: T,
    objectToCheckPropertyOn: T,
    objectWithDefaultValues: T,
    ...keys: string[]
) => {
    const lastKeyIndex = keys.length - 1;
    const shortenedKeys = keys.slice(0, lastKeyIndex);
    const obj = shortenedKeys.reduce((prev, curr) => prev?.[curr], objectToSetPropertyOf);
    const testVal = keys.reduce((prev, curr) => prev?.[curr], objectToCheckPropertyOn);
    const defVal = keys.reduce((prev, curr) => prev?.[curr], objectWithDefaultValues);

    const setVal = testVal ?? defVal;

    obj[keys[lastKeyIndex]] = setVal;

    return setVal;
};
