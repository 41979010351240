import { DEFAULT_ARCHIVE_STATUSES, DEFAULT_LIVE_STATUSES } from '@/redux/modules/search/search.types';
import { findCategoryContainingSomeCategoryId } from './search/searchUtils';
import { formatHyphenatedDateOnly } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { getCategoryLandingPageBaseUrl, getCategoryUrlWithParams, getPriceGuideBaseUrl } from './urls';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import cloneDeep from 'lodash/cloneDeep';
import FacetTypes from '../types/search/enums/facetTypes';
import isEmpty from 'lodash/isEmpty';
import paramTypes from '@/types/search/enums/paramTypes';

export { parse as parseQueryString, stringify as stringifyQuery } from 'qs';
export const DEFAULT_STATUS_FOR_ONLINE_SEARCH = 'online';
export const DEFAULT_STATUS_FOR_ARCHIVED_SEARCH = 'archive';

const addCurrencyRangeToParams = (
    params: any,
    paramName: string,
    min: string | number,
    max: string | number,
    currencyPrefix = 'USD:'
) => {
    let newParams = cloneDeep(params);
    const minValue = min !== '' && min !== undefined ? min : -1;
    const maxValue = max !== '' && max !== undefined ? max : -1;

    newParams[paramName] = `${currencyPrefix}${minValue}:${maxValue}`;
    return newParams;
};

const addSaleStartToParams = (params: any, min: number, max: number) => {
    let newParams = cloneDeep(params);
    if (!isNaN(min)) {
        newParams[paramTypes.SALE_DATE_MIN] = min;
    }
    if (!isNaN(max)) {
        newParams[paramTypes.SALE_DATE_MAX] = max;
    }
    return newParams;
};

const concatenatePath = (category, catId) => {
    if (!category) {
        return '';
    }
    if (category.id === catId) {
        return category.id;
    }
    const nextCategory = findCategoryContainingSomeCategoryId(catId, category.categories);
    return [category.id, ...concatenatePath(nextCategory, catId)];
};

const modifyParamsRemoveCountry = (params) => {
    let newParams = cloneDeep(params);
    newParams = removeParamsFromList(newParams, paramTypes.COUNTRY_CODE_ID, params.country);
    return newParams;
};

const modifyParamsRemoveDistance = (params) => {
    let newParams = cloneDeep(params);
    newParams = removeParamsFromList(newParams, paramTypes.DISTANCE_ID, params.distance);
    return newParams;
};

const removeFacetFromUrl = (pathname, categoryId, params, baseUrl) => {
    // expecting a url in the pattern of "/c/name/name/id/id", "/c/name/id" or "/c"
    const url = pathname;
    // split the current location into its separate parts
    const splitUrl = url.split('/');
    // remove the empty string and the c (created because of the format '/c/')from the array
    const categoryNames = splitUrl.splice(2, splitUrl.length - 2);
    // split the category names and Ids (there will always be an even number of Ids and names in the pattern name/id or name/name/id/id etc.)
    const categoryIds = categoryNames.splice(categoryNames.length / 2, categoryNames.length - categoryNames.length / 2);

    let id = '';
    let name = '';
    // begin reassembling the two parts by adding each of the ones we want but not the one we don't
    for (let i = 0; i < categoryIds.length; i += 1) {
        if (categoryIds[i] !== categoryId) {
            id += `${categoryIds[i]}/`;
            name += `/${categoryNames[i]}`;
        }
    }

    if (name === '') {
        let url = baseUrl;
        if (!isEmpty(params)) {
            url = getCategoryUrlWithParams(url, params);
        }
        return url;
    }

    return getCategoryUrlWithParams(`/${splitUrl[1]}${name}/${id.substring(0, id.length - 1)}/`, params);
};

// Only exported for the tests
export const addValueToParamsList = (params: any, paramName: string, value: any) => {
    let newParams = cloneDeep(params);
    if (newParams[paramName]) {
        const ids = newParams[paramName].split(',');
        if (!ids.some((id) => id === value)) {
            ids.push(value);
            newParams[paramName] = ids.join(',');
        }
    } else {
        newParams[paramName] = value;
    }
    return newParams;
};

export const getBuyNowFromQueryParams = (queryParams: any) => Boolean(queryParams.buyNow);

export const getCategoryIdsFromQueryParams = (queryParams: any): string | undefined => {
    // TODO:
    // This IF should not be needed anymore, queryParams will never have a paramters property
    if (queryParams && queryParams.parameters) {
        try {
            const parameters = JSON.parse(decodeURIComponent(queryParams.parameters));
            if (parameters && parameters.category_id) {
                return parameters.category_id;
            }
        } catch (e) {
            return undefined;
        }
    } else if (queryParams && queryParams.category_id) {
        return queryParams.category_id;
    }

    return undefined;
};

export const getFacetFromSearchQueryParameter = (paramKey: string) => {
    let facetId;
    if (paramKey === paramTypes.AUCTION_HOUSE_ID) {
        facetId = FacetTypes.AUCTION_HOUSE_ID;
    } else if (paramKey === paramTypes.COUNTRY_CODE_ID) {
        facetId = FacetTypes.COUNTRY_CODE_ID;
    } else if (paramKey === paramTypes.CURRENCY_ID) {
        facetId = FacetTypes.CURRENCY_ID;
    } else if (paramKey === paramTypes.CURRENT_BID_ID) {
        facetId = FacetTypes.CURRENT_BID_ID;
    } else if (paramKey === paramTypes.DISTANCE_ID) {
        facetId = FacetTypes.DISTANCE_ID;
    } else if (paramKey === paramTypes.SHIPPING) {
        facetId = FacetTypes.SHIPPING;
    } else if (paramKey === paramTypes.ESTIMATED_PRICE_ID) {
        facetId = FacetTypes.ESTIMATED_PRICE_ID;
    } else if (paramKey === paramTypes.HAMMER_PRICE_ID) {
        facetId = FacetTypes.HAMMER_PRICE_ID;
    } else if (paramKey === paramTypes.RESERVE) {
        facetId = FacetTypes.RESERVE;
    } else if (paramKey === paramTypes.SALE_TYPE_ID) {
        facetId = FacetTypes.SALE_TYPE_ID;
    } else if (paramKey === paramTypes.TOP_RATED_ID) {
        facetId = FacetTypes.TOP_RATED_ID;
    } else if (paramKey === paramTypes.PAYMENT) {
        facetId = FacetTypes.PAYMENT;
    }
    return facetId;
};

export const getKeywordFromQueryParams = (queryParams: { [attribute: string]: string | number | boolean }) => {
    if (queryParams) {
        return queryParams['keyword'] || '';
    }
    return '';
};

export const getFilterTermFromQueryParams = (queryParams: { [attribute: string]: string | number | boolean }) => {
    if (queryParams) {
        return queryParams['filterTerm'] || '';
    }
    return '';
};

export const getPageFromQueryParams = (queryParams: any) => parseInt(queryParams.page, 10) || 1;

export const getPathWithoutParams = (pathname: string, facets: any) => {
    const categoryFacets = facets.filter((fac) => fac.id === FacetTypes.CATEGORY_ID);
    let selectedCategoryFacet = [];
    if (categoryFacets.length) {
        selectedCategoryFacet = categoryFacets[0].categories.facets.filter((fac) => fac.selected);
    }
    // expecting a url in the pattern of "/c/name/name/id/id", "/c/name/id" or "/c"
    const url = pathname;
    // split the current location into its separate parts
    const splitUrl = url.split('/');
    // just gets the Id for the first category in the url
    const id = splitUrl[(splitUrl.length + 1) / 2];
    // just gets the name for the first category in the url
    const name = splitUrl[2];
    // if the length is 3 (meaning there's no categories in the url) or id is greater than or equal to the firstNonCategoryId (meaning it's a facet not a category)
    if (splitUrl.length <= 3 || selectedCategoryFacet.length <= 0) {
        return getCategoryLandingPageBaseUrl();
    }

    return `/${splitUrl[1]}/${name}/${id}`;
};

export const isArchivedSearch = (queryParams: any, pathname: string) => {
    let searchStatus = DEFAULT_LIVE_STATUSES;

    if (queryParams.status === 'archive' || (pathname && pathname.includes(getPriceGuideBaseUrl()))) {
        searchStatus = DEFAULT_ARCHIVE_STATUSES;
    }

    let archivedSearch = true;

    DEFAULT_ARCHIVE_STATUSES.forEach((status) => {
        archivedSearch = archivedSearch && searchStatus && searchStatus.some((x) => x === status);
    });

    return archivedSearch;
};

export const modifyParamsRemoveAllExceptCategories = (params: any, facets: any[]) => {
    let newParams = cloneDeep(params);

    Object.keys(newParams).forEach((key) => {
        if (
            key === paramTypes.FILTER_TERM ||
            key === paramTypes.KEYWORD ||
            key === paramTypes.PAGE ||
            key === paramTypes.PAGE_SIZE_LEGACY_SUPPORT ||
            key === paramTypes.PAGE_SIZE ||
            key === paramTypes.SORT ||
            key === paramTypes.STATUS
        ) {
            return;
        }

        if (key === paramTypes.CATEGORY_ID) {
            const categoryFacets = facets.filter((fac) => fac.id === FacetTypes.CATEGORY_ID);
            const selectedCategoryFacet = categoryFacets[0].categories.facets.filter((fac) => fac.selected);
            if (selectedCategoryFacet.length > 0) {
                newParams[key] = selectedCategoryFacet[0].id;
            } else {
                delete newParams[key];
            }
        } else {
            delete newParams[key];
        }
    });
    return newParams;
};

export const modifyParamsRemoveCampaignId = (params: any) => {
    let newParams = cloneDeep(params);
    newParams = removeParamsFromList(newParams, paramTypes.CAMPAIGN_ID, newParams.campaignId);
    return newParams;
};

export const modifyParamsRemoveCountryAndDistance = (params: any) => {
    let newParams = cloneDeep(params);
    newParams = modifyParamsRemoveCountry(newParams);
    newParams = modifyParamsRemoveDistance(newParams);
    return newParams;
};

export const modifyParamsReplaceCountry = (params: any, countryId: string) => {
    let newParams = cloneDeep(params);
    newParams = modifyParamsRemoveCountry(newParams);
    newParams = countryId !== 'US' ? modifyParamsRemoveDistance(newParams) : newParams;
    const paramName = parameterNameFromFacetId(FacetTypes.COUNTRY_CODE_ID);
    newParams = addValueToParamsList(newParams, paramName, countryId);
    return newParams;
};

export const modifyParamsRemoveFacet = (params: any, facetId: string) => {
    let newParams = cloneDeep(params);
    const paramName = parameterNameFromFacetId(facetId);
    delete newParams[paramName];
    return newParams;
};

export const modifyParamsRemovePublishDate = (params: any) => {
    let newParams = cloneDeep(params);
    delete newParams[paramTypes.PUBLISH_DATE_MIN];
    delete newParams[paramTypes.PUBLISH_DATE_MAX];
    return newParams;
};

export const modifyParamsRemoveSaleDates = (params: any) => {
    let newParams = cloneDeep(params);
    delete newParams[paramTypes.SALE_DATE_MIN];
    delete newParams[paramTypes.SALE_DATE_MAX];
    return newParams;
};

export const modifyParamsRemovePage = (params: any) => {
    const newParams = cloneDeep(params);
    delete newParams.page;
    return newParams;
};

export const modifyParamsRemoveSaveSearch = (params: any) => {
    const newParams = cloneDeep(params);
    delete newParams.saveSearch;
    return newParams;
};

export const modifyParamsRemoveDomesticFlatShipping = (params: any) => {
    const newParams = cloneDeep(params);
    delete newParams.domesticFlatShipping;
    return newParams;
};

export const modifyParamsRemoveParamByFacetId = (params: any, facetId) => {
    const newParams = cloneDeep(params);
    delete newParams[parameterNameFromFacetId(facetId)];
    return newParams;
};

export const modifyParamsRemoveUnfollowSearch = (params: any) => {
    const newParams = cloneDeep(params);
    delete newParams.action;
    delete newParams.savedSearchId;
    delete newParams.signature;
    return newParams;
};

export const modifyParamsWhenChecked = (params: any, facetId: string, value: any, checked: boolean) => {
    let newParams = cloneDeep(params);
    const paramName = parameterNameFromFacetId(facetId);
    if (checked) {
        newParams = addValueToParamsList(newParams, paramName, value);
        if (facetId === FacetTypes.COUNTRY_CODE_ID) {
            newParams = modifyParamsRemoveDistance(newParams);
        }
    } else {
        newParams = removeParamsFromList(newParams, paramName, value);
    }
    return newParams;
};

export const modifyParamsWithDate = (params: any, date: any) => {
    const newParams = cloneDeep(params);
    if (date) {
        newParams.date = formatHyphenatedDateOnly(date);
    } else {
        delete newParams.date;
    }
    return newParams;
};

export const modifyAuctionCalendarParamsSaleStart = (params: any, soonest: Boolean) => {
    const newParams = cloneDeep(params);
    if (soonest) {
        newParams.sort = SearchSortAndDirection.TimeMostRecent;
    } else {
        delete newParams.sort;
    }
    return newParams;
};

export const modifyParamsWithDistance = (
    params: any,
    country: string,
    zipCode: string,
    range: string,
    measurement: string
) => {
    let newParams = cloneDeep(params);
    newParams = modifyParamsReplaceCountry(newParams, country);
    const paramName = paramTypes.DISTANCE_ID;
    newParams[paramName] = `${country}:${zipCode}:${range}:${measurement}`;
    return newParams;
};

export const modifyParamsWithKeyword = (params: any, keyword: string) => {
    const newParams = cloneDeep(params);
    newParams.keyword = keyword;
    if (keyword === '') {
        delete newParams.keyword;
    }
    return newParams;
};

export const modifyParamsWithFilterTerm = (params: any, filterTerm: string) => {
    const newFilterTerm = filterTerm.trim();
    const paramName = paramTypes.FILTER_TERM;
    const filterTermEntry = String(params[paramName]);
    let filterTerms = filterTermEntry.split(',');
    if (filterTerms.includes(newFilterTerm)) {
        return removeParamsFromList(params, paramName, filterTerm);
    } else {
        return addValueToParamsList(params, paramName, filterTerm);
    }
};

export const modifyParamsWithPage = (params: any, pageNum: number) => {
    let newParams = cloneDeep(params);
    const paramName = paramTypes.PAGE;
    if (pageNum === 1) {
        delete newParams[paramName];
    } else {
        newParams[paramName] = pageNum;
    }
    return newParams;
};

export const modifyParamsWithPageSize = (params: any, pageSize: number | string) => {
    const newParams = cloneDeep(params);
    newParams.pageSize = pageSize;
    return newParams;
};

export const modifyParamsWithDomesticFlatShipping = (params: any, flatShipping: 0 | true) => {
    const newParams = cloneDeep(params);
    newParams.domesticFlatShipping = flatShipping;
    return newParams;
};

export const modifyParamsWithRange = (params: any, facetId: string, min: string | number, max: string | number) => {
    let newParams = cloneDeep(params);
    const paramName = parameterNameFromFacetId(facetId);

    newParams =
        facetId === FacetTypes.SALE_START
            ? addSaleStartToParams(newParams, Number(min), Number(max))
            : addCurrencyRangeToParams(newParams, paramName, min, max);

    return newParams;
};

export const modifyParamsWithSort = (params: any, sortOption: string) => {
    let newParams = cloneDeep(params);
    const paramName = paramTypes.SORT;
    newParams[paramName] = sortOption;
    return newParams;
};

export const modifyParamsWithTaxonomyAll = (params: any, facetId: string, categoryId: string) => {
    let newParams = cloneDeep(params);
    const paramName = parameterNameFromFacetId(facetId);
    newParams = removeParamsFromList(newParams, paramName, categoryId);
    return newParams;
};

export const modifyParamsWithTaxonomyParent = (
    params: any,
    facetId: number | string,
    categoryId: number | string,
    parentId: number | string
) => {
    let newParams = cloneDeep(params);
    const paramName = parameterNameFromFacetId(facetId);
    newParams = removeParamsFromList(newParams, paramName, categoryId);
    newParams = addValueToParamsList(newParams, paramName, parentId);
    return newParams;
};

export const modifyParamsWithTaxonomySelected = (params: any, facetId: string, value: any, facets: any[]) => {
    let newParams = cloneDeep(params);
    const paramName = parameterNameFromFacetId(facetId);
    const treeFacets = facets.filter((category) => category.id === facetId);

    const result = findCategoryContainingSomeCategoryId(value, treeFacets);

    const numbersToRemove = concatenatePath(result, value);
    if (numbersToRemove && numbersToRemove.length > 0) {
        numbersToRemove.forEach((idToRemove) => {
            if (idToRemove !== facetId || idToRemove !== value) {
                newParams = removeParamsFromList(newParams, paramName, idToRemove);
            }
        });
    }
    newParams = addValueToParamsList(newParams, paramName, value);

    newParams = modifyParamsRemovePage(newParams);

    return newParams;
};

// Only exported for the tests
export const parameterNameFromFacetId = (facetId: number | string) => {
    let paramName = '';
    if (facetId === FacetTypes.AUCTION_HOUSE_ID) {
        paramName = paramTypes.AUCTION_HOUSE_ID;
    } else if (
        facetId === FacetTypes.MATERIALS_AND_TECHNIQUES_ID ||
        facetId === FacetTypes.CREATOR_ID ||
        facetId === FacetTypes.STYLE_PERIOD_ID ||
        facetId === FacetTypes.CATEGORY_ID ||
        facetId === FacetTypes.ORIGIN_ID
    ) {
        paramName = paramTypes.CATEGORY_ID;
    } else if (facetId === FacetTypes.COUNTRY_CODE_ID) {
        paramName = paramTypes.COUNTRY_CODE_ID;
    } else if (facetId === FacetTypes.CURRENCY_ID) {
        paramName = paramTypes.CURRENCY_ID;
    } else if (facetId === FacetTypes.CURRENT_BID_ID) {
        paramName = paramTypes.CURRENT_BID_ID;
    } else if (facetId === FacetTypes.ESTIMATED_PRICE_ID) {
        paramName = paramTypes.ESTIMATED_PRICE_ID;
    } else if (facetId === FacetTypes.HAMMER_PRICE_ID) {
        paramName = paramTypes.HAMMER_PRICE_ID;
    } else if (facetId === FacetTypes.SALE_TYPE_ID) {
        paramName = paramTypes.SALE_TYPE_ID;
    } else if (facetId === FacetTypes.SHIPPING) {
        paramName = paramTypes.SHIPPING;
    } else if (facetId === FacetTypes.TOP_RATED_ID) {
        paramName = paramTypes.TOP_RATED_ID;
    } else if (facetId === FacetTypes.PAYMENT) {
        paramName = paramTypes.PAYMENT;
    } else if (facetId === FacetTypes.AUDIO_VIDEO) {
        paramName = paramTypes.LIVE;
    }

    return paramName;
};

export type ParseQueryParams = {
    category_id: string;
    country?: string;
    current_bid?: string;
    distance?: string;
    estimated_price?: string;
    filterTerm?: string;
    hammer_price?: string;
    house_id?: string;
    itemId?: number;
    keyword?: string;
    location: string;
    page: number;
    pageSize?: number | 'all';
    sale_type?: string;
    savedSearchId?: number;
    shipping?: string;
    signature?: string;
    size: number;
    sort: SearchSortAndDirection;
    status: string;
    topRated?: string;
};

// Based off https://stackoverflow.com/a/3855394/148256
export const parseQueryParams = <T extends object>(queryString: string): T & Partial<ParseQueryParams> => {
    if (!queryString) {
        return {} as T & Partial<ParseQueryParams>;
    }

    let params: any = (/^[?#]/.test(queryString) ? queryString.slice(1) : queryString)
        .split('&')
        .reduce((params, param) => {
            try {
                const [k, v] = param.split('=');
                let key = decodeURIComponent(k.replace(/\+/g, ' ')) || '';
                // Lowercasing the keys will make the front end code cleaner (and hopefully will not break things)
                // key = key.toLowerCase();
                // If the value is undefined than return a empty string.
                const value = typeof v === 'undefined' ? '' : decodeURIComponent(v.replace(/\+/g, ' '));
                // If there is a key, and it is not a duplicate, set it
                if (key && !params[key]) {
                    // Clean up the nasty parameters object
                    params[key] = key === 'parameters' && typeof value === 'string' ? JSON.parse(value) : value;
                }
            } catch (err) {
                // If we catch an error, don't alter params, the reduce funtion will continue on without that param
            }
            return params;
        }, {});

    // Combine the parameters object
    if (params.parameters) {
        params =
            typeof params.parameters === 'object'
                ? {
                      ...params.parameters,
                      ...params,
                  }
                : params;
        delete params.parameters;
    }

    // Make sure we use campaign_id instead of campaignId
    if (params.campaignId || params.campaign_id) {
        params.campaign_id = params.campaignId || params.campaign_id;
        if (params.campaignId) {
            delete params.campaignId;
        }
    }

    // Make sure category_id is a string
    if (params.category_id) {
        params.category_id = params.category_id.toString();
    }
    // Make sure page is an int
    if (params.page) {
        params.page = parseInt(params.page, 10);
    }

    // Make sure page is an int (or "all")
    if (params.pageSize) {
        params.pageSize = params.pageSize === 'all' ? 'all' : parseInt(params.pageSize, 10);
    }

    // sort should always be a string
    if (params.sort) {
        if (typeof params.sort !== 'string') {
            delete params.sort;
        }
    }

    // location should always be a string
    if (params.location) {
        if (typeof params.location !== 'string') {
            delete params.location;
        }
    }

    return params;
};

export const removeArchiveFacetFromUrl = (pathname: string, categoryId: string, params: any) =>
    removeFacetFromUrl(pathname, categoryId, params, getPriceGuideBaseUrl());

// Only exported for the tests
export const removeParamsFromList = (params: any, paramName: string, value: any) => {
    let newParams = cloneDeep(params);

    if (Reflect.has(newParams, paramName)) {
        const values = newParams[paramName].split(',');
        const index = values.indexOf(value);
        if (index > -1) {
            values.splice(index, 1);
        }

        if (values.length === 0) {
            delete newParams[paramName];
        } else {
            newParams[paramName] = values.join(',');
        }
    }
    return newParams;
};

export const removeQueryParams = (queryParams: any, keys: string[]) => {
    const query = {
        ...queryParams,
    };
    keys.map((k) => delete query[k]);
    return query;
};

export const removeUpcomingFacetFromUrl = (pathname: string, categoryId: string, params: any) =>
    removeFacetFromUrl(pathname, categoryId, params, getCategoryLandingPageBaseUrl());

type ValidatedQueryParams = {
    buyNow?: boolean | string;
    filter: string;
    page: number;
    sort: string;
    tab: string;
    terms: string;
};

// TODO this should use SearchSortEnum
export const validateQueryParams = (
    query: Partial<ValidatedQueryParams>,
    sortOptions?: { [sortId: string]: string } | false,
    defaultTab: string = 'upcoming',
    filterOptions?: { [sortId: string]: string } | false
    // buyNow could be a string when it comes in, but it will be a boolean when it goes back out
): ValidatedQueryParams & { buyNow: boolean } => {
    const queryPage = query?.page || 0;
    const querySort = query?.sort || '';
    const queryFilter = query?.filter || '';
    const page = !isNaN(queryPage) && queryPage > 0 ? Number(queryPage) : 1;
    const sort = sortOptions?.[querySort] ? querySort : '';
    const filter = filterOptions?.[queryFilter] ? queryFilter : '';
    const tab = query?.tab || defaultTab;
    const terms = query?.terms || '';
    const buyNow = query?.buyNow === 'false' ? false : Boolean(query?.buyNow);

    return {
        buyNow,
        filter,
        page,
        sort,
        tab,
        terms,
    };
};

export const makeAllCategoriesQueryString = (ids: number[]) => {
    let queries = '?';
    ids.forEach((id, index) => {
        if (index === 7) {
            queries += `${index}=${id}`;
        } else {
            queries += `${index}=${id}&`;
        }
    });
    return queries;
};
