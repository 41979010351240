import { AccountDetailsEventFlow, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackReviewBidViewedPayload = TrackingPayload & {
    eventFlow: AccountDetailsEventFlow;
};

export const trackReviewBidViewedAnalytics = (payload: TrackReviewBidViewedPayload) => {
    track(TrackingEvents.AuthModal.ReviewBidViewed, payload);
};

export const useTrackReviewBidViewedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(() => {
        trackReviewBidViewedAnalytics({
            eventFlow: AccountDetailsEventFlow.Bidding,
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
        });
    }, [pathname, url]);
};
