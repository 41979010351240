import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '../core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const trackAllFiltersDrawerOpenedAnalytics = (payload: TrackingPayload) => {
    track(TrackingEvents.SearchPage.AllFiltersDrawerOpened, payload);
};

export const useTrackAllFiltersDrawerOpened = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(() => {
        return trackAllFiltersDrawerOpenedAnalytics({
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
        });
    }, [pathname, url]);
};
