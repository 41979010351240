import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const trackNotificationsCTAClicked = (payload: TrackingPayload) => {
    track(TrackingEvents.CTA.CTAClicked, payload);
};

export const useTrackBrowserNotificationsClicked = () => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    return useCallback(() => {
        trackNotificationsCTAClicked({
            buttonAction: 'Turns on Browser Notifications',
            CTAName: 'Enable Browser Notifications',
            eventTrigger: 'Enable Browser Notification Banner',
            pageName: getAmplitudePageNameFromPath(pathname),
            url: getFullUrl(deployment, pathname),
        });
    }, [deployment, pathname]);
};

export const useTrackOutbidNotificationsClicked = () => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    return useCallback(
        (outBidNotificationActive: boolean) => {
            trackNotificationsCTAClicked({
                buttonAction: outBidNotificationActive ? 'Outbid Notification Enabled' : 'Outbid Notification Disabled',
                CTAName: "Notify Me When I'm Outbid",
                eventTrigger: 'User is leading bidder after submitting bid',
                pageName: getAmplitudePageNameFromPath(pathname),
                url: getFullUrl(deployment, pathname),
            });
        },
        [deployment, pathname]
    );
};
