export type CatalogAnnouncement = {
    body: string;
    id: number;
    title: string;
};

export const emptyCatalogAnnouncement: CatalogAnnouncement = {
    body: '',
    id: 0,
    title: '',
};
