import { AnalyticsCatalogRegistrationStatus } from '@/utils/analytics/core.types';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';

export const getAnalyticsRegistrationStatus = (
    status: ReturnType<typeof getCatalogRegistration>
): AnalyticsCatalogRegistrationStatus => {
    if (status.limited) {
        return AnalyticsCatalogRegistrationStatus.ApprovedWithLimit;
    }
    if (status.approved) {
        return AnalyticsCatalogRegistrationStatus.Approved;
    }
    if (status.declined) {
        return AnalyticsCatalogRegistrationStatus.Declined;
    }
    if (status.pending) {
        return AnalyticsCatalogRegistrationStatus.Pending;
    }
    return AnalyticsCatalogRegistrationStatus.NotRegistered;
};
