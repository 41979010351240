import { AccountDetailsEventFlow, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackVerifyEmailSubmittedPayload = TrackingPayload & {
    eventFlow: AccountDetailsEventFlow;
};

const trackVerifyEmailSubmittedAnalytics = (payload: TrackVerifyEmailSubmittedPayload) => {
    track(TrackingEvents.Account.EmailVerificationCodeSubmitted, payload);
};

export const useTrackVerifyEmailSubmittedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(() => {
        trackVerifyEmailSubmittedAnalytics({
            eventFlow: AccountDetailsEventFlow.AccountCreation,
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
        });
    }, [pathname, url]);
};
