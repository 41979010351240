import { parseQueryParams } from './queryParams';
import { toQueryString } from './urls';

const getBase = (url: string) => {
    if (!url) {
        return '';
    }
    if (url.includes('#')) {
        return url.substring(0, url.indexOf('#'));
    }
    if (url.includes('?')) {
        return url.substring(0, url.indexOf('?'));
    }
    return url;
};

const getQueryString = (url: string) => {
    const base = getBase(url);
    const query = url.substring(base.length);
    if (query !== '' && query !== '/') {
        return query;
    }
    return '';
};

// Returns the canonical url or the page
export const buildCanonicalUrl = (canonicalPageUrl: string, pageNum: number) => {
    const base = getBase(canonicalPageUrl);
    const originalQuery = getQueryString(canonicalPageUrl);
    const queryParams = parseQueryParams(originalQuery);

    const withPage: any = {
        ...queryParams,
    };

    // remove parameters and page
    delete withPage.parameters;
    delete withPage.page;

    // if page is defined and not 1, add it
    if (pageNum && pageNum !== 1) {
        withPage.page = pageNum;
    }

    const query = toQueryString(withPage);

    return query === '' ? base : `${base}?${query}`;
};

export const pageSlicer = (catalog: any, count?: number | 'all', page: number = 1) => {
    if (count === 'all' || typeof count === 'undefined') {
        return catalog;
    }
    const pages = Math.ceil(catalog.length / count);
    let currentPage = page;
    if (page > pages) {
        currentPage = pages;
    }
    const start = (currentPage - 1) * count;
    return catalog.slice(start, start + count);
};

export const getPageSizeFromCookie = (cookie: number | 'all') => (cookie === 'all' ? 'all' : Number(cookie));
