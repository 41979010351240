export type BiddingInfo = {
    bidActivityCount: number;
    bidCount: number;
    bidderBidTimestamp: number;
    bidderHasBid: boolean;
    bidderHasHighBid: boolean;
    bidderMaxBid: number;
    isAvailable: boolean;
    isLocked: boolean;
    isPassed: boolean;
    isPaused: boolean;
    isReserveMet: boolean;
    isSold: boolean;
    itemId: number;
    leadingBid: number;
    salePrice: number;
};

export const EmptyBiddingInfo: BiddingInfo = {
    bidActivityCount: 0,
    bidCount: 0,
    bidderBidTimestamp: 0,
    bidderHasBid: false,
    bidderHasHighBid: false,
    bidderMaxBid: 0,
    isAvailable: true,
    isLocked: false,
    isPassed: false,
    isPaused: false,
    isReserveMet: true,
    isSold: false,
    itemId: 0,
    leadingBid: 0,
    salePrice: 0,
};
