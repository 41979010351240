export type ItemDetail = {
    agentId: number;
    conditionReport: string;
    description: string;
    dimensions: string;
    domesticFlatShipping: number;
    externalUrl: string;
    itemId: number;
    shippingDimensions: string;
    shippingWeight: string;
    weight: string;
};

export const emptyItemDetail: ItemDetail = {
    agentId: null,
    conditionReport: '',
    description: '',
    dimensions: '',
    domesticFlatShipping: null,
    externalUrl: '',
    itemId: 0,
    shippingDimensions: '',
    shippingWeight: '',
    weight: '',
};
