/* eslint-disable typescript-sort-keys/string-enum */
export enum UserBidPlacedItemsSortEnum {
    CURRENT_PRICE_ASC = 'currentPrice',
    CURRENT_PRICE_DESC = '-currentPrice',
    ESTIMATED_PRICE_ASC = 'estimatedPrice',
    ESTIMATED_PRICE_DESC = '-estimatedPrice',
    BID_COUNT_ASC = 'bidCount',
    BID_COUNT_DESC = '-bidCount',
    LOT_NUMBER_ASC = 'lotNumber',
    LOT_NUMBER_DESC = '-lotNumber',
    SALE_START_ASC = 'saleStart',
    SALE_START_DESC = '-saleStart',
    VIEWED_ASC = 'viewed',
    VIEWED_DESC = '-viewed',
    MY_BID_ASC = 'myBid',
    MY_BID_DESC = '-myBid',
}

export enum UserBidPlacedItemsStatusEnum {
    UPCOMING = 'available',
    ENDED = 'ended',
}

export enum UserBidPlacedItemsBidStatusEnum {
    ALL = 'all',
    LEADING = 'leading',
    OUTBID = 'outbid',
}
/* eslint-enable typescript-sort-keys/string-enum */
