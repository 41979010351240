import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '../core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export enum ItemCardMenuClickTrigger {
    ExcludeHouseFromSearch = 'Exclude House From Search',
    RetractBid = 'Retract Bid',
    SeeSimilarItems = 'See Similar Items',
}

type TrackItemCardMenuClickPayload = TrackingPayload & {
    houseId: number;
    itemId: number;
    trigger: ItemCardMenuClickTrigger;
};

export const trackItemCardMenuClickAnalytics = (payload: TrackItemCardMenuClickPayload) => {
    track(TrackingEvents.Item.ItemCardMenuClicked, payload);
};

export const useTrackItemCardMenuClickAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        ({ houseId, itemId, trigger }: Omit<TrackItemCardMenuClickPayload, 'pageName' | 'url'>) => {
            return trackItemCardMenuClickAnalytics({
                houseId,
                itemId,
                pageName: getAmplitudePageNameFromPath(pathname),
                trigger,
                url,
            });
        },
        [pathname, url]
    );
};
