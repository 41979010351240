export enum ShippingVisibility {
    Pending = 'pending',
    Unavailable = 'disabled',
    Uncalculated = '',
    Visible = 'enabled',
}

export type ShippingEstimate = {
    city: string;
    currency: string;
    expirationDate: string;
    message: string;
    quoteId: string;
    quoteNumber: string;
    shippingAvailable: boolean;
    shippingProvider: number;
    state: string;
    total: number;
    transitDays: number;
    visibility: ShippingVisibility;
};
