enum Facets {
    AUCTION_HOUSE_ID = 'auctionHouse',
    AUDIO_VIDEO = 'audioVideo',
    BUYERS_PREMIUM_ID = 'hasBuyersPremium',
    CATEGORY_ID = 'F1',
    COUNTRY_CODE_ID = 'countryCode',
    CREATOR_ID = 'creator',
    CURRENCY_ID = 'currency',
    CURRENT_BID_ID = 'currentBid',
    DISTANCE_ID = 'distance',
    ESTIMATED_PRICE_ID = 'estimate',
    HAMMER_PRICE_ID = 'hammerPrice',
    KEYWORD_ID = 'keyword',
    LOCATION_ID = 'location',
    MATERIALS_AND_TECHNIQUES_ID = 'materialsAndTechniques',
    ORIGIN_ID = 'F2',
    PAYMENT = 'payment',
    RESERVE = 'reserve',
    SALE_START = 'saleStart',
    SALE_TYPE_ID = 'sale_type',
    SHIPPING = 'shipping',
    STYLE_PERIOD_ID = 'stylePeriod',
    TOP_RATED_ID = 'topRated',
}

export default Facets;
