import { AccountDetailsEventFlow, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TrackReviewYourAuctionRegistrationViewedPayload = TrackingPayload & {
    eventFlow: AccountDetailsEventFlow.AuctionRegistration;
};

const trackReviewYourAuctionRegistrationAnalyticsViewed = (
    payload: TrackReviewYourAuctionRegistrationViewedPayload
) => {
    track(TrackingEvents.AuthModal.ReviewAuctionRegistrationViewed, payload);
};

export const useTrackReviewYourAuctionRegistrationAnalyticsViewed = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(() => {
        trackReviewYourAuctionRegistrationAnalyticsViewed({
            eventFlow: AccountDetailsEventFlow.AuctionRegistration,
            pageName: getAmplitudePageNameFromPath(pathname),
            url,
        });
    }, [pathname, url]);
};
