import { AccountDetailsEventFlow, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { track } from '@/utils/analytics/core';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const trackCompleteAccountSetupBannerClicked = (payload: TrackingPayload) => {
    track(TrackingEvents.Account.CompleteAccountSetupBannerClicked, payload);
};

const trackCompleteAccountCTAClicked = (payload: TrackingPayload) => {
    track(TrackingEvents.Account.CompleteAccountSetupCTAClicked, payload);
};

const trackMyAccountIconClicked = (payload: TrackingPayload) => {
    track(TrackingEvents.Account.MyAccountIconClicked, payload);
};

export const useTrackCompleteAccountSetupBannerClicked = () => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    return useCallback(() => {
        trackCompleteAccountSetupBannerClicked({
            pageName: getAmplitudePageNameFromPath(pathname),
            url: getFullUrl(deployment, pathname),
        });
    }, [deployment, pathname]);
};

export const useTrackCompleteAccountCTAClicked = () => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    return useCallback(() => {
        trackCompleteAccountCTAClicked({
            description: 'User clicked on the Complete My Profile CTA within the My Account dropdown',
            eventFlow: AccountDetailsEventFlow.AccountCreation,
            pageName: getAmplitudePageNameFromPath(pathname),
            url: getFullUrl(deployment, pathname),
        });
    }, [deployment, pathname]);
};

export const useTrackMyAccountIconClicked = () => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    return useCallback(() => {
        trackMyAccountIconClicked({
            description: 'User clicks on the My Account icon in the nav header',
            pageName: getAmplitudePageNameFromPath(pathname),
            url: getFullUrl(deployment, pathname),
        });
    }, [deployment, pathname]);
};
