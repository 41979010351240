export type Seller = {
    address: string;
    address2: string;
    alias: string;
    betas: number[];
    city: string;
    country: string;
    countryCode: string;
    logoId: string;
    name: string;
    regionCode: string;
    sellerId: number;
    stateCode: string;
    stateName: string;
    zip: string;
};

export const EmptySeller: Seller = {
    address: '',
    address2: '',
    alias: '',
    betas: [],
    city: '',
    country: '',
    countryCode: '',
    logoId: '',
    name: '',
    regionCode: '',
    sellerId: 0,
    stateCode: '',
    stateName: '',
    zip: '',
};
