type AcceptedMessageBuilderParams = {
    amount: number;
    bidderId: number;
    created: number;
    currency: string;
    myBid: boolean;
    outbid: boolean;
    paddleNumber: number;
    previousBidAmount: number;
    source: string;
};

type MessageData = {
    amount: number;
    bidderId: number;
    currency: string;
    paddleNumber: number;
    previousBidAmount: number;
    source: string;
};

export const acceptedMessageBuilder = ({
    amount,
    bidderId,
    created,
    currency,
    myBid,
    outbid,
    paddleNumber,
    previousBidAmount,
    source,
}: AcceptedMessageBuilderParams) => {
    const data: MessageData = {
        amount,
        bidderId,
        currency,
        paddleNumber,
        previousBidAmount,
        source,
    };

    const nonLaBidder = bidderId === 0;
    const messages: Array<{
        created: number;
        data: typeof data;
        messageId: string;
        type: string;
    }> = [];

    switch (source) {
        case 'Absentee':
            if (myBid) {
                // Fake a bid accepted because we dont get info on the bid that was rejected because of the absentee bid
                messages.push({
                    created,
                    data: {
                        ...data,
                        bidderId: 0,
                    },
                    messageId: 'bidding_console.console.messages.competing_bid_amount',
                    type: 'accepted',
                });
                // My absentee bid was accepted
                messages.push({
                    created: created + 1,
                    data,
                    messageId: 'bidding_console.console.messages.my_bid_amount_absentee',
                    type: 'mybid',
                });
            } else if (outbid) {
                if (previousBidAmount !== 0) {
                    // Fake that the users bid was accepted so that we can show the correct messaging about them being outbid by an absentee bid
                    messages.push({
                        created,
                        data,
                        messageId: 'bidding_console.console.messages.my_bid_amount',
                        type: 'mybid',
                    });
                    // Message saying they were outbid by an absentee bid
                    messages.push({
                        created: created + 1,
                        data,
                        messageId: 'bidding_console.console.messages.you_been_outbid_by_liveauctioneers_absentee',
                        type: 'outbid-absentee',
                    });
                    // Message saying that an absentee bid was accepted
                    messages.push({
                        created: created + 2,
                        data,
                        messageId: 'bidding_console.console.messages.liveauctioneers_bid_amount_absentee',
                        type: 'accepted',
                    });
                } else {
                    // Message saying that an absentee bid was accepted
                    messages.push({
                        created: created + 2,
                        data,
                        messageId: 'bidding_console.console.messages.liveauctioneers_bid_amount_absentee',
                        type: 'accepted',
                    });
                }
            } else {
                // Fake a bid accepted because we dont get info on the bid that was rejected because of the absentee bid
                messages.push({
                    created,
                    data: {
                        ...data,
                        bidderId: 0,
                    },
                    messageId: 'bidding_console.console.messages.competing_bid_amount',
                    type: 'accepted2',
                });
                // Message indicating that someone was outbid by an absentee bid
                messages.push({
                    created: created + 1,
                    data,
                    messageId: 'bidding_console.console.messages.competing_bidder_outbid_by_liveauctioneers_absentee',
                    type: 'absentee-outbid',
                });
                // Message saying that an absentee bid was accepted
                messages.push({
                    created: created + 2,
                    data,
                    messageId: 'bidding_console.console.messages.liveauctioneers_bid_amount_absentee',
                    type: 'accepted',
                });
            }

            break;
        case 'Reserve':
            // Message indicating that the reserve was not met
            messages.push({
                created: created + 1,
                data,
                messageId: 'bidding_console.console.messages.reserve_not_met',
                type: 'reserve',
            });
            break;
        default:
            if (myBid) {
                // Message indicating that the users bid was accepted
                messages.push({
                    created,
                    data,
                    messageId: 'bidding_console.console.messages.my_bid_amount',
                    type: 'mybid',
                });
            } else {
                // message indicating that a bid was accepted
                messages.push({
                    created,
                    data,
                    messageId: nonLaBidder
                        ? 'bidding_console.console.messages.competing_bid_amount'
                        : 'bidding_console.console.messages.liveauctioneers_bid_amount',
                    type: 'accepted',
                });
            }

            break;
    }

    return messages;
};
