export type BidderAddress = {
    accountPurpose?: string;
    address1: string;
    address2: string;
    address3?: string;
    addressId: number;
    avalaraVerified?: boolean;
    bidderId: number;
    city: string;
    countryCode: string;
    enabled?: boolean;
    firstName: string;
    isDefault?: boolean;
    lastName: string;
    organizationName: string;
    partialAddress?: string;
    phoneNumber: string;
    postalCode: string;
    shippingOptionType?: string;
    singleLineAddress?: string;
    state: string;
    suggestion?: string;
    updatedDate?: string;
    /**
     * Potential Values => Meaning
     * * 1 => User-validated address
     * * 2 => Verify-suggested address
     */
    validatorID?: 1 | 2;
    validatorProvider?: AddressValidatorProvider;
    verifiedStatus?: number;
    zip?: string;
};

export enum AddressValidatorProvider {
    Suggested = 'suggested',
    User = 'user',
}

export type BidderAddressSuggestion = BidderAddress & {
    partialAddress: string;
    singleLineAddress: string;
};

export type TypeAheadAddress = {
    address1: string;
    address2: string;
    city: string;
    countryCode: string;
    partialAddress: string;
    state: string;
    suggestion: string;
    zip: string;
};

export const emptyBidderAddress: BidderAddress = {
    address1: '',
    address2: '',
    addressId: 0,
    bidderId: 0,
    city: '',
    countryCode: '',
    firstName: '',
    lastName: '',
    organizationName: '',
    phoneNumber: '',
    postalCode: '',
    state: '',
};
