import { removeHtml as ccRemoveHtml, sanitizeHtml } from '@liveauctioneers/caterwaul-components/lib/utils/safelyRender';

export { safelyRender, sanitizeHtml } from '@liveauctioneers/caterwaul-components/lib/utils/safelyRender';

/**
 * remove all html and return a text string - also subs space for <br/> and <li> tags
 * @param text
 * @returns string
 */
const removeHtml = (text: string) => {
    let manuallyCleanedHtml = text || '';
    // Manually replace the HTML line breaks, as bruteFilter doesn't replace the removed HTML tags
    manuallyCleanedHtml = manuallyCleanedHtml.replace(/<br ?\/?>/g, ' ');
    // Manually replace line item tags, as bruteFilter doesn't replace the removed HTML tags
    manuallyCleanedHtml = manuallyCleanedHtml.replace(/<li>/g, ' ').trimStart();
    return ccRemoveHtml(manuallyCleanedHtml);
};

/**
 * sanitizes meta data by removing HTML, and replaces whitespace characters with single spaces to improve readability
 * @param text
 * @returns string
 */
export const safelyEscapeText = (text: string) => {
    return sanitizeHtml(removeHtml(text)).replace(/\s\s+/g, ' ');
};

/**
 * sanitizes meta data by removing HTML
 * @param text
 * @returns string
 */
export const safelyEscapeTextSpaceful = (text: string) => {
    return sanitizeHtml(removeHtml(text));
};
