import { calculateBuyersPremium } from '@/utils/calculateBuyersPremium';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getCatalogBuyersPremium } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCurrencyConversion } from '@/redux/modules/currency';
import {
    getItemModelBuyNowPrice,
    getItemModelCatalogId,
    getItemModelCurrency,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackBuyNowPressedAnalyticsPayload } from '@/utils/analytics/bidding/bidding.types';
import { TrackingEvents } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const trackBuyNowPressedAnalytics = (payload: TrackBuyNowPressedAnalyticsPayload) => {
    track(TrackingEvents.Bidding.BuyNowPressed, payload);
};

export const useTrackBuyNowPressedAnalytics = (itemId: number) => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    const catalogId = useAppSelector((state) => getItemModelCatalogId(state, itemId));
    const buyNowPrice = useAppSelector((state) => getItemModelBuyNowPrice(state, itemId));
    const currency = useAppSelector((state) => getItemModelCurrency(state, itemId));
    const currencyConversion = useAppSelector((state) => getCurrencyConversion(state, currency));
    const buyersPremium = useAppSelector((state) => getCatalogBuyersPremium(state, catalogId));

    return useCallback(
        (makeAnOfferButton: boolean) => {
            const conversionToUsd = currencyConversion?.conversionToUsd ?? 0;
            const { amount: buyersPremiumAmount } = calculateBuyersPremium(buyNowPrice, buyersPremium);
            const lotHammerPriceUsd = buyNowPrice * conversionToUsd;
            const totalAmount = buyNowPrice + buyersPremiumAmount;
            const lotTotalPriceUsd = totalAmount * conversionToUsd;

            trackBuyNowPressedAnalytics({
                lotHammerPriceUsd,
                lotTotalPriceUsd,
                makeAnOfferButton,
                pageName: getAmplitudePageNameFromPath(pathname),
                url,
            });
        },
        [buyNowPrice, buyersPremium, currencyConversion?.conversionToUsd, pathname, url]
    );
};
