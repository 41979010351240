import { BuyersPremium, EmptyBuyersPremium } from './BuyersPremium';
import { CoverLot, EmptyCoverLot } from './CoverLot';
import { PaymentOption } from './PaymentOption';
import { PaymentProviders } from '@/enums';
import type { AuctionStatus } from '@/types/LiveAuctionState';

export type CatalogAddress = {
    address1: string;
    address2: string;
    city: string;
    country: string;
    fullCountryName: string;
    lat: number;
    lng: number;
    postalCode: string;
    state: string;
};

export type Catalog = {
    absenteeBidders: number;
    achPayments: boolean;
    address: CatalogAddress;
    billingCheckout: boolean;
    buyersPremium: BuyersPremium;
    catalogId: number;
    catalogStatus: AuctionStatus;
    /**
     * `closedLotsCount` is the number of lots that were closed at the time the page was loaded.
     */
    closedLotsCount?: number;
    coverLots?: CoverLot[];
    currency: string;
    currencySymbol: string;
    description: string;
    hasExplicitMaterial: boolean;
    hasVideo: boolean;
    highRiskPayments: boolean;
    imagePath: string;
    isAutopay: boolean;
    isCatalogOnly: boolean;
    isExtended: boolean;
    isInstantAutopay: boolean;
    isSEOExcluded: boolean;
    isTimed: boolean;
    isTimedPlus: boolean;
    liveAuctioneersPayments: boolean;
    lotsListed: number;
    paymentOptions: PaymentOption;
    paymentProvider?: PaymentProviders;
    payrixPayments?: boolean;
    preview: string;
    publishDateTs?: number;
    requiredAch?: number; //When 0, users may select their payment option. When set, any balance over this number(in cents) must use ach.
    saleEndEstimatedTs?: number;
    saleEndTs: number;
    saleStartTs: number;
    sellerId: number;
    sellerName: string;
    title: string;
};

export const EmptyCatalog: Catalog = {
    absenteeBidders: 0,
    achPayments: false,
    address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        fullCountryName: '',
        lat: 0,
        lng: 0,
        postalCode: '',
        state: '',
    },
    billingCheckout: false,
    buyersPremium: EmptyBuyersPremium,
    catalogId: 0,
    catalogStatus: undefined,
    closedLotsCount: 0,
    coverLots: [EmptyCoverLot],
    currency: 'USD',
    currencySymbol: '',
    description: '',
    hasExplicitMaterial: false,
    hasVideo: false,
    highRiskPayments: false,
    imagePath: '',
    isAutopay: false,
    isCatalogOnly: false,
    isExtended: false,
    isInstantAutopay: false,
    isSEOExcluded: false,
    isTimed: false,
    isTimedPlus: false,
    liveAuctioneersPayments: false,
    lotsListed: 0,
    paymentOptions: {},
    preview: '',
    publishDateTs: 0,
    requiredAch: 0,
    saleEndEstimatedTs: 0,
    saleEndTs: 0,
    saleStartTs: 0,
    sellerId: 0,
    sellerName: '',
    title: '',
};
