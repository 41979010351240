import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const TRACK_EXPOSURE_LOCAL_STORAGE_KEY = 'TRACK_EXPERIMENT_EXPOSURE';

type ExposedToExperimentPayload = TrackingPayload & {
    experimentName: string;
    variantName: string;
};

export const trackExposedToExperiment = (payload: ExposedToExperimentPayload) => {
    track(TrackingEvents.Experiments.ExposedToExperiment, payload);
};

export const useTrackExposedToExperiment = (experimentName: string, variantName: string) => {
    const deployment = useAppSelector(getDeployment);
    const { pathname } = useLocation();

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;

        const hasTrackingInformation = Boolean(localStorage.getItem(TRACK_EXPOSURE_LOCAL_STORAGE_KEY));
        if (!hasTrackingInformation) {
            localStorage.setItem(TRACK_EXPOSURE_LOCAL_STORAGE_KEY, JSON.stringify({}));
        }
    }, []);

    return useCallback(() => {
        if (!isMounted.current) {
            return;
        }

        const currentTracking: {
            [experimentName: string]: boolean;
        } = JSON.parse(localStorage.getItem(TRACK_EXPOSURE_LOCAL_STORAGE_KEY));

        if (!currentTracking[experimentName]) {
            trackExposedToExperiment({
                experimentName,
                pageName: getAmplitudePageNameFromPath(pathname),
                url: getFullUrl(deployment, pathname),
                variantName,
            });
            const newTracking: typeof currentTracking = {
                ...currentTracking,
                [experimentName]: true,
            };
            localStorage.setItem(TRACK_EXPOSURE_LOCAL_STORAGE_KEY, JSON.stringify(newTracking));
        }
    }, [deployment, experimentName, pathname, variantName]);
};
