import { DEFAULT_ARCHIVE_STATUSES, DEFAULT_LIVE_STATUSES } from '@/redux/modules/search/search.types';
import { getPriceGuideBaseUrl } from '../urls';
import find from 'lodash/find';
import some from 'lodash/some';

const getParentPathForQuery = (category) => {
    let categoryToQuery = '';
    if (category.selected && category.parents && category.parents.length > 0) {
        let parentsToQuery = '';
        category.parents.forEach((parent) => {
            parentsToQuery += `${parent.id}/`;
        });
        categoryToQuery = `${parentsToQuery}${categoryToQuery}`;
    }
    return categoryToQuery;
};

export const concatenatePath = (category: any, catId: number) => {
    if (!category) {
        return catId;
    }
    if (category.id === catId) {
        let categoryToQuery = category.id;
        categoryToQuery = `${getParentPathForQuery(category)}${categoryToQuery}`;

        return categoryToQuery;
    }
    const nextCategory = findCategoryContainingSomeCategoryId(catId, category.categories);

    return `${category.id}/${concatenatePath(nextCategory, catId)}`;
};

export const findCategoryContainingSomeCategoryId = (categoryId: number, categories: any[]) => {
    if (!categories) {
        return false;
    }
    const trees = categories;
    if (some(trees, (c) => c.id === categoryId)) {
        return find(trees, (c) => c.id === categoryId);
    }
    return find(trees, (c) => findCategoryContainingSomeCategoryId(categoryId, c.categories));
};

export const translateStatus = (queryParams: any, pathname: string) => {
    let status = DEFAULT_LIVE_STATUSES;

    if (queryParams.status === 'archive' || (pathname && pathname.includes(getPriceGuideBaseUrl()))) {
        status = DEFAULT_ARCHIVE_STATUSES;
    }

    return status;
};

export const sortCountryFacets = (countryFacets: any) => {
    return countryFacets.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    });
};
