// currently unclear so adding an enum
// TODO make this universal
import { AuctionStatus } from '@/types/LiveAuctionState';
import { ShippingProviderId } from '@/enums/shippingProviders';

export enum BuyNowStatus {
    Available = 1,
    Unavailable = 0,
}

/* eslint-disable typescript-sort-keys/interface */
// Organized data parts so they are easier to understand
export type ItemSummary = {
    // BiddingInfo
    bidActivityCount: number;
    bidCount: number;
    bidderBidTimestamp: number;
    bidderHasBid: boolean;
    bidderHasHighBid: boolean;
    // whether the user has placed a bid and is waiting on a response
    bidderIsPlacingBid?: boolean;
    bidderMaxBid: number;
    bidderWonItem: boolean;
    imageVersion: number;
    isAvailable: boolean;
    isLocked: boolean;
    isPassed: boolean;
    isPaused: boolean;
    isReserveMet: boolean;
    isSold: boolean;
    leadingBid: number;
    salePrice: number;
    slug?: string;

    // Buy Now
    buyNowPrice: number;
    buyNowStatus: BuyNowStatus;

    // Catalog
    catalogId: number;
    catalogStatus: AuctionStatus;
    catalogTitle: string;
    currency: string;
    isCatalogOnly: boolean;
    isLiveAuction: boolean;
    isTimedAuction: boolean;
    isTimedPlusAuction?: boolean;
    isExtended: boolean;
    lotsListed: number;
    saleStartTs: number;
    saleEndTs?: number;

    // House
    sellerCity: string;
    sellerCountryCode: string;
    sellerId: number;
    sellerLogoId: string;
    sellerName: string;
    sellerStateCode: string;
    shippingProviderId: ShippingProviderId;
    houseRating?: number;
    houseReviewCount?: number;
    houseIsTopRated?: number;

    // Item
    highBidEstimate: number;
    index: number;
    isDeleted?: boolean;
    isSEOExcluded: boolean;
    itemId: number;
    similarItemsURL?: string;
    // measured in seconds
    lotEndTimeEstimatedTs?: number;
    lotNumber: string;
    lowBidEstimate: number;
    photos: number[];
    promoted?: boolean;
    shortDescription: string;
    startPrice: number;
    title: string;
    lotLocationCity?: string;
    lotLocationCountryCode?: string;
    lotLocationRegion?: string;

    // Shipping
    flatRateShippingAmount: number;
    hasFlatRateShipping: boolean;
    hasFreeShipping: boolean;
    hasLiveShipping: boolean;

    lotLocation: {
        city: string;
        countryCode: string;
        postalCode: string;
        region: string;
    };
};
/* eslint-enable typescript-sort-keys/interface */

/* eslint-disable sort-keys/sort-keys-fix */
export const emptyItemSummary: ItemSummary = {
    // BiddingInfo
    bidActivityCount: 0,
    bidCount: 0,
    bidderBidTimestamp: 0,
    bidderHasBid: false,
    bidderHasHighBid: false,
    bidderMaxBid: 0,
    bidderWonItem: false,
    imageVersion: 0,
    isAvailable: true,
    isLocked: false,
    isPassed: false,
    isPaused: false,
    isReserveMet: false,
    isSold: false,
    itemId: 0,
    leadingBid: 0,
    salePrice: 0,
    slug: '',
    // BuyNow
    buyNowPrice: 0,
    buyNowStatus: BuyNowStatus.Unavailable,
    // Catalog
    catalogId: 0,
    catalogStatus: AuctionStatus.Unknown,
    catalogTitle: '',
    currency: 'USD',
    isCatalogOnly: false,
    isDeleted: false,
    isLiveAuction: false,
    isTimedAuction: false,
    isTimedPlusAuction: false,
    isExtended: false,
    lotsListed: 0,
    saleStartTs: 0,
    saleEndTs: 0,
    // House
    sellerCity: '',
    sellerCountryCode: '',
    sellerId: 0,
    sellerLogoId: '',
    sellerName: '',
    sellerStateCode: '',
    shippingProviderId: 0,
    houseRating: 0,
    houseReviewCount: 0,
    houseIsTopRated: 0,
    // Item
    highBidEstimate: 0,
    index: 0,
    isSEOExcluded: false,
    lotEndTimeEstimatedTs: 0,
    lotNumber: '0',
    lowBidEstimate: 0,
    photos: [],
    promoted: false,
    shortDescription: '',
    similarItemsURL: '',
    startPrice: 0,
    title: '',
    lotLocationCity: '',
    lotLocationCountryCode: '',
    lotLocationRegion: '',
    // Shipping
    flatRateShippingAmount: 0,
    hasFlatRateShipping: false,
    hasFreeShipping: false,
    hasLiveShipping: false,

    lotLocation: {
        city: '',
        countryCode: '',
        postalCode: '',
        region: '',
    },
};
/* eslint-enable sort-keys/sort-keys-fix */

export type FetchItemsSummariesResponse = {
    error: boolean;
    message: string;
    payload: {
        items: ItemSummary[];
        lotsClosed: number;
        pageCount: number;
        recordCount: number;
        totalRecords: number;
    };
};

type ResponseItemSummary = Omit<ItemSummary, 'lotLocation'> & {
    lotLocationCity: string;
    lotLocationCountryCode: string;
    lotLocationPostalCode: string;
    lotLocationRegion: string;
};

type TransformParams = {
    payload: Omit<FetchItemsSummariesResponse['payload'], 'items'> & {
        items: ResponseItemSummary[];
    };
};

export const transformItemSummary = ({ payload }: TransformParams) => {
    const items = payload.items.map((item) => {
        const { lotLocationCity, lotLocationCountryCode, lotLocationPostalCode, lotLocationRegion, ...summary } = item;
        return {
            ...summary,
            lotLocation: {
                city: lotLocationCity?.trim() || '',
                countryCode: lotLocationCountryCode?.toUpperCase() || '',
                postalCode: lotLocationPostalCode || '',
                region: lotLocationRegion?.trim() || '',
            },
        };
    });
    return {
        error: false,
        message: 'Successfully retrieved the items.',
        payload: {
            ...payload,
            items,
        },
    };
};
