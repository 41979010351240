import { TrackBidPlacedPrebidStatus } from '@/utils/analytics/bidding/biddingAnalytics.types';

export const getUserPrebidStatus = (
    bidderHasBid: boolean,
    bidderHasHighBid: boolean,
    isReserveMet: boolean
): TrackBidPlacedPrebidStatus => {
    if (bidderHasBid) {
        if (!bidderHasHighBid) {
            return TrackBidPlacedPrebidStatus.Outbid;
        }
        if (isReserveMet) {
            return TrackBidPlacedPrebidStatus.Leading;
        }
        return TrackBidPlacedPrebidStatus.ReserveNotMet;
    }
    return TrackBidPlacedPrebidStatus.NoBid;
};
