import { track as amplitudeTrack } from '@amplitude/analytics-browser';
import { convertPayloadToHumanReadableFieldNames } from '@/utils/analytics/utils/convertToHumanReadableFieldNames';
import { TrackingPayload } from '@/utils/analytics/core.types';

export const isClientSide = () => typeof window !== 'undefined' && typeof document !== 'undefined';
export const isTestRun = typeof jest !== 'undefined';

export const track = (eventName: string, eventPayload: TrackingPayload) => {
    // avoid tracking during SSR and on test runs
    if (isClientSide && !isTestRun) {
        amplitudeTrack(eventName, convertPayloadToHumanReadableFieldNames(eventPayload));
    }
};
