import { AmplitudePageName } from '@/utils/getPageNameFromPath';

export const TrackingEvents = {
    Account: {
        AccountCreated: 'Account Created',
        AddShippingAddressViewed: 'Add Shipping Address Viewed',
        CompleteAccountSetupBannerClicked: 'Complete Account Setup Banner Clicked',
        CompleteAccountSetupCTAClicked: 'Complete My Profile Clicked',
        EmailVerificationCodeSubmitted: 'Email Verification Code Submitted',
        MyAccountIconClicked: 'My Account Clicked',
        ResendEmailVerificationCodeClicked: 'Resend Email Verification Code Clicked',
        VerifyYourAddressViewed: 'Verify Your Address Viewed',
    },
    Auctioneer: {
        AuctioneerFollowed: 'Auctioneer Followed',
        AuctioneerUnfollowed: 'Auctioneer Unfollowed',
    },
    AuthModal: {
        AddPaymentMethodViewed: 'Add Payment Method Viewed',
        OutbidViewed: 'Someone Has Outbid You Viewed',
        PlaceBidViewed: 'Place Bid Viewed',
        ReserveNotMetViewed: 'Reserve Not Met Viewed',
        ReviewAuctionRegistrationViewed: 'Review Your Auction Registration Viewed',
        ReviewBidViewed: 'Review Bid Viewed',
    },
    Bidding: {
        BidPlaced: 'Bid Placed',
        BidRetracted: 'Bid Retracted',
        BuyNowPressed: 'Buy Now Pressed',
        BuyNowRequestSent: 'Buy Now Request Sent',
        PlaceBidPressed: 'Place Bid Pressed',
        RequestHigherBidLimitClicked: 'Request Higher Bid Limit Clicked',
    },
    CTA: {
        CTAClicked: 'CTA Clicked',
    },
    Experiments: {
        ExposedToExperiment: 'User Exposed To Experiment',
    },
    GlobalNavigation: {
        HeaderCategoryClicked: 'Header Category Clicked',
        ViewWonItemsBannerClicked: 'View Won Items Banner Clicked',
    },
    Item: {
        ItemCardClicked: 'Item Card Clicked',
        ItemCardMenuClicked: 'Item Card Menu Clicked',
        ItemSaved: 'Lot Saved',
        ItemUnsaved: 'Saved Lot Removed',
    },
    Registration: {
        AuctionRegistrationSubmitted: 'Auction Registration Submitted',
        RegisterForAuctionPressed: 'Register For Auction Pressed',
    },
    Search: {
        SearchCompleted: 'Search Completed',
        SearchFollowed: 'Search Followed',
        SearchRefined: 'Search Refined',
        SearchUnfollowed: 'Search Unfollowed',
    },
    SearchPage: {
        AllFiltersDrawerClosed: 'All Filters Drawer Closed',
        AllFiltersDrawerOpened: 'All Filters Drawer Opened',
        AuctionHouseSuggestionClicked: 'Auction House Suggestion Clicked',
    },
    Sharing: {
        ShareButtonClicked: 'Share Button Clicked',
    },
} as const;

type BaseTrackingPayload = {
    pageName: AmplitudePageName;
    url: string;
};

export type TrackingPayload = BaseTrackingPayload & Record<string, any>;

export enum AnalyticsCatalogRegistrationStatus {
    Approved = 'Approved',
    ApprovedWithLimit = 'Approved With Limit',
    Declined = 'Declined',
    NotRegistered = 'Not Registered',
    Pending = 'Pending',
}

export enum AccountDetailsEventFlow {
    AccountCreation = 'Account Creation',
    AccountSettings = 'Account Settings',
    AuctionRegistration = 'Auction Registration',
    Bidding = 'Bidding',
    Checkout = 'Checkout',
}

export enum SearchCompletedTrigger {
    Other = 'Other',
    SearchBar = 'Search Bar',
}
