// Currently we're only caring about pages that might have bidding capability
import { useLocation } from 'react-router-dom';

const BidderPageNames = {
    '/': 'Home Page',
    '/c/': 'Category Page',
    '/catalog/': 'Catalog Page',
    '/catalog/search': 'Auction Calendar',
    '/console/': 'Bidding Console',
    '/dashboard/followed-auctioneers': 'My Followed Auctioneers',
    '/dashboard/my-auctions': 'My Auctions',
    '/dashboard/my-bids': 'My Bids',
    '/dashboard/saved-items': 'My Saved Items',
    '/dashboard/search-and-alerts': 'My Saved Search Results',
    '/featured/': 'Collection Page',
    '/item/': 'Item Page',
    '/recent': 'Recent Items Page',
    '/recommended': 'Recommended Items Page',
    '/search/': 'Search Page',
} as const;

export type BidderPageName = (typeof BidderPageNames)[keyof typeof BidderPageNames];

/**
 * @deprecated As we transition to Amplitude, we should avoid this function
 * @see {getAmplitudePageNameFromPath}
 * @param path The url pathname from which name we want
 */
export const getPageNameFromPath = (path: string = ''): BidderPageName => {
    // the only page that could potentially be confused
    if (path === '/') {
        return BidderPageNames['/'];
    } else if (path.includes('/catalog/search')) {
        return BidderPageNames['/catalog/search'];
    } else if (path.includes('/catalog/')) {
        return BidderPageNames['/catalog/'];
    }

    let pageName;
    Object.keys(BidderPageNames).forEach((partialPath) => {
        // exclude '/' here as it's already filtered out in the above if-else tree
        if (path.includes(partialPath) && partialPath !== '/') {
            pageName = BidderPageNames[partialPath];
        }
    });

    return pageName;
};

export enum AmplitudePageName {
    About = 'About Page',
    AccountSettings = 'Account Settings Page',
    Auctioneer = 'Auctioneer Page',
    AuctioneersDirectory = 'Auctioneers Directory',
    AuctionsNearMe = 'Auctions Near Me Page',
    BecomeASeller = 'Become A Seller Page',
    Careers = 'Careers Page',
    Catalog = 'Catalog Page',
    Category = 'Category Page',
    Checkout = 'Checkout Page',
    Consign = 'Consign Page',
    EmailsNotifications = 'Emails & Notifications Page',
    ExploreCategories = 'Explore Categories Page',
    FollowedAuctioneers = 'Followed Auctioneers Page',
    FollowedSearches = 'Followed Searches Page',
    ForYou = 'For You Page',
    Home = 'Home Page',
    HowAuctionsWork = 'How Auctions Work Page',
    LiveConsole = 'Live Console Page',
    LotDetails = 'Lot Details Page',
    LotPriceResults = 'Lot Price Results Page',
    Messages = 'Messages Page',
    MyAuctions = 'My Auctions Page',
    MyBids = 'My Bids Page',
    MyItems = 'My Items Page',
    PriceGuide = 'Price Guide Page',
    SearchAuctionPriceResults = 'Search Auction Price Results Page',
    SearchPriceResults = 'Search Price Results Page',
    SearchResults = 'Search Results Page',
    SellerReviews = 'Seller Reviews',
    UpcomingAuctions = 'Upcoming Auctions Page',
    WhySell = 'Why Sell Page',
    WonItems = 'Won Items Page',
}

/**
 * @see https://atgmedia.sharepoint.com/:x:/s/ATG-Product/ETtZLz1z_uVOgZF3_y53IW4BYe5GyNUVu7NBIPwz1HuIjA?e=Xh15NO
 * @param currentPath - The current route the user is on. This will likely be populated by `location.pathName` as a result of the useLocation hook.
 * @param currentParams - The current query params the user has. This will likely be populated by `location.search` as a result of the useLocation hook.
 * @returns A human-readable page name for the given path, based on our Amplitude documentation page names.
 */
export const getAmplitudePageNameFromPath = (
    currentPath: ReturnType<typeof useLocation>['pathname'],
    currentParams?: ReturnType<typeof useLocation>['search']
): AmplitudePageName => {
    const path = currentPath.toLowerCase() ?? '';
    const params = currentParams?.toLowerCase() ?? '';

    if (path.includes('/about')) {
        return AmplitudePageName.About;
    }
    if (path.includes('/dashboard/profile')) {
        return AmplitudePageName.AccountSettings;
    }
    if (path.includes('/auctioneer')) {
        if (path.includes('/directory')) {
            return AmplitudePageName.AuctioneersDirectory;
        }
        return AmplitudePageName.Auctioneer;
    }
    if (path.includes('/find-auctions-near-me')) {
        return AmplitudePageName.AuctionsNearMe;
    }
    if (path.includes('/pages/sell')) {
        return AmplitudePageName.BecomeASeller;
    }
    if (path.includes('/careers')) {
        return AmplitudePageName.Careers;
    }
    if (path.includes('/catalog')) {
        if (path.includes('/search')) {
            return AmplitudePageName.UpcomingAuctions;
        }
        return AmplitudePageName.Catalog;
    }
    if (path.includes('/c/')) {
        // if /c/ or /c are at the end of the path
        if (new RegExp(/\/c\/?$/i).test(path)) {
            return AmplitudePageName.ExploreCategories;
        }
        return AmplitudePageName.Category;
    }
    if (path.includes('/checkout')) {
        return AmplitudePageName.Checkout;
    }
    if (path.includes('/consignments')) {
        return AmplitudePageName.Consign;
    }
    if (path.includes('/dashboard/emails-and-notifications')) {
        return AmplitudePageName.EmailsNotifications;
    }
    if (path.includes('/dashboard/followed-auctioneers-for-you')) {
        return AmplitudePageName.FollowedAuctioneers;
    }
    if (path.includes('/dashboard/followed-searches')) {
        return AmplitudePageName.FollowedSearches;
    }
    if (path.includes('/dashboard/for-you')) {
        return AmplitudePageName.ForYou;
    }
    if (path.includes('/how-auctions-work')) {
        return AmplitudePageName.HowAuctionsWork;
    }
    if (path.includes('/console')) {
        return AmplitudePageName.LiveConsole;
    }
    if (path.includes('/item/')) {
        return AmplitudePageName.LotDetails;
    }
    if (path.includes('/price-result/')) {
        return AmplitudePageName.LotPriceResults;
    }
    if (path.includes('/dashboard/messages')) {
        return AmplitudePageName.Messages;
    }
    if (path.includes('/dashboard/my-auctions')) {
        return AmplitudePageName.MyAuctions;
    }
    if (path.includes('/dashboard/my-bids')) {
        return AmplitudePageName.MyBids;
    }
    if (path.includes('/dashboard/saved-items')) {
        return AmplitudePageName.MyItems;
    }
    if (path.includes('/price-guide/')) {
        return AmplitudePageName.PriceGuide;
    }
    if (path.includes('/auction-results')) {
        return AmplitudePageName.SearchAuctionPriceResults;
    }
    if (path.includes('/search')) {
        if (params.includes('status=archive')) {
            return AmplitudePageName.SearchPriceResults;
        }
        return AmplitudePageName.SearchResults;
    }
    if (path.includes('/seller-reviews')) {
        return AmplitudePageName.SellerReviews;
    }
    if (path.includes('/features')) {
        return AmplitudePageName.WhySell;
    }
    if (path.includes('/dashboard/won-items')) {
        return AmplitudePageName.WonItems;
    }

    return AmplitudePageName.Home;
};
