const getExpirationFromToken = (token: string = '') => {
    const splitToken = token.split('.');
    if (splitToken.length < 2) {
        console.warn('Failed to decode JWT expiration');
        return;
    }
    try {
        if (!atob(splitToken[1])) {
            return;
        }
        const payload = JSON.parse(atob(splitToken[1]));
        return new Date(payload.exp * 1000);
    } catch (e) {
        console.warn('Failed to decode JWT expiration', e);
        return new Date(0);
    }
};

export default getExpirationFromToken;
