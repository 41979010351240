import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';

type TrackShareButtonClickedPayload = TrackingPayload & {
    itemUrl: string;
    network: string;
};

export const trackShareButtonClickedAnalytics = (payload: TrackShareButtonClickedPayload) => {
    track(TrackingEvents.Sharing.ShareButtonClicked, payload);
};
