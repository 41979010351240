import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '../core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export enum AllFiltersDrawerClosedReason {
    Backdrop = 'Backdrop',
    CloseButton = 'Close Button',
    Escape = 'Escape',
    ViewResults = 'View Results',
}

type TrackAllFiltersDrawerClosedPayload = TrackingPayload & {
    reason: AllFiltersDrawerClosedReason;
};

export const trackAllFiltersDrawerClosedAnalytics = (payload: TrackAllFiltersDrawerClosedPayload) => {
    track(TrackingEvents.SearchPage.AllFiltersDrawerClosed, payload);
};

export const useTrackAllFiltersDrawerClosed = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        ({ reason }: Omit<TrackAllFiltersDrawerClosedPayload, 'pageName' | 'url'>) => {
            return trackAllFiltersDrawerClosedAnalytics({
                pageName: getAmplitudePageNameFromPath(pathname),
                reason,
                url,
            });
        },
        [pathname, url]
    );
};
