export type BuyNow = {
    availableTs: number;
    itemId: number;
    price: number;
    status: string;
};

export const EmptyBuyNow = {
    availableTs: 0,
    itemId: 0,
    price: 0,
    status: '',
};
