import { AnalyticsCatalogRegistrationStatus, TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { track } from '@/utils/analytics/core';
import { TrackBidPlacedPrebidStatus } from '@/utils/analytics/bidding/biddingAnalytics.types';

export enum TrackBidPlacedBidType {
    Absentee = 'Absentee Bid',
    Live = 'Live Bid',
}

export type TrackBidPlacedPayload = TrackingPayload & {
    auctionRegistrationStatus: AnalyticsCatalogRegistrationStatus;
    bidAmount: number;
    bidAmountUsd: number;
    bidType: TrackBidPlacedBidType;
    catalogCurrency: string;
    lotCategoryLvl1: string;
    lotCategoryLvl2: string;
    userPrebidStatus: TrackBidPlacedPrebidStatus;
};

export const trackBidPlacedAnalytics = (payload: TrackBidPlacedPayload) => {
    track(TrackingEvents.Bidding.BidPlaced, payload);
};
