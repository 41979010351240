import { AmplitudeSearchQueryParams } from '../utils/createAmplitudeSearchQueryParams';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getUrlFromPathname } from '@/redux/modules/config';
import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export type TrackSearchRefinedPayload = TrackingPayload &
    AmplitudeSearchQueryParams & { numberOfSearchResults: number };

export const trackSearchRefinedAnalytics = (payload: TrackSearchRefinedPayload) => {
    track(TrackingEvents.Search.SearchRefined, payload);
};

export const useTrackSearchRefinedAnalytics = () => {
    const { pathname } = useLocation();
    const url = useAppSelector((state) => getUrlFromPathname(state, pathname));

    return useCallback(
        (payload: Omit<TrackSearchRefinedPayload, 'pageName' | 'url'>) => {
            trackSearchRefinedAnalytics({
                ...payload,
                numberOfSearchResults: payload.numberOfSearchResults,
                pageName: getAmplitudePageNameFromPath(pathname),
                searchKeywords: payload.searchKeywords,
                url,
            });
        },
        [pathname, url]
    );
};
