import { track } from '@/utils/analytics/core';
import { TrackingEvents, TrackingPayload } from '@/utils/analytics/core.types';

type TrackItemSavedPayload = TrackingPayload & {
    lotCategoryLvl1: string;
    lotCategoryLvl2: string;
};

export const trackItemSavedAnalytics = (payload: TrackItemSavedPayload) => {
    track(TrackingEvents.Item.ItemSaved, payload);
};
