export default {
    AUCTION_HOUSE_ID: 'house_id',
    BUY_NOW: 'buyNow',
    BUY_NOW_DATE: 'buyNowDate',
    CAMPAIGN_ID: 'campaignId',
    CATEGORY_ID: 'category_id',
    COUNTRY_CODE_ID: 'country',
    CURRENCY_ID: 'currency',
    CURRENT_BID_ID: 'current_bid',
    DISTANCE_ID: 'distance',
    ESTIMATED_PRICE_ID: 'estimated_price',
    FILTER_TERM: 'filterTerm',
    HAMMER_PRICE_ID: 'hammer_price',
    KEYWORD: 'keyword',
    LIVE: 'live',
    PAGE: 'page',
    PAGE_SIZE: 'pageSize',
    PAGE_SIZE_LEGACY_SUPPORT: 'pagesize',
    PAYMENT: 'payment',
    PUBLISH_DATE_MAX: 'publishDateMax',
    PUBLISH_DATE_MIN: 'publishDateMin',
    RESERVE: 'reserve',
    SALE_DATE_MAX: 'saleDateMax',
    SALE_DATE_MIN: 'saleDateMin',
    SALE_TYPE_ID: 'sale_type',
    SHIPPING: 'shipping',
    SORT: 'sort',
    STATUS: 'status',
    TOP_RATED_ID: 'topRated',
} as const;
